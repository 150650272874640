import './index.scss';
import { PollWidgetFieldsFragment, useCastPollVoteMutation } from '../../generate/graphql';
import { useEffect, useState } from 'react';
import moment from 'moment';
import 'primeicons/primeicons.css';
import { GroupAvatar } from '../groupAvatar';
import { useReactiveVar } from '@apollo/client';
import { currentUserIdVar } from '../../graphql/reactive/login';

const PollWidget = ({ _id, question, options, pollType }: PollWidgetFieldsFragment & { eventId: string }) => {
  const [totalVotes, setTotalVotes] = useState(0);
  const [newOptions, setNewOptions] = useState<any>([]);
  const [castPollVote, { loading: submitLoading }] = useCastPollVoteMutation();

  const currentUserId = useReactiveVar(currentUserIdVar);

  useEffect(() => {
    const newOptionArray: any[] = [];
    let votes = 0;

    // @ts-ignore
    const tempOptions = [...options];

    if (pollType === 'TIME') tempOptions.sort((a, b) => parseInt(a.description) - parseInt(b.description));

    tempOptions.forEach((option, i: number) => {
      newOptionArray[i] = { ...option, voteMap: {} };

      option.votes.forEach((vote: { _id: string }) => {
        newOptionArray[i].voteMap[vote._id] = true;
        votes += 1;
      });
    });

    setNewOptions(newOptionArray);
    if (votes !== totalVotes) setTotalVotes(votes);
  }, [options]);

  const onVote = (optionDescription: string) => {
    if (currentUserId === '') return;
    let votedOptions = newOptions.filter((option: any) => option.voteMap[currentUserId]).map((option: any) => option.description);

    if (votedOptions.includes(optionDescription)) {
      votedOptions = votedOptions.filter((option: string) => option !== optionDescription);
    } else {
      votedOptions.push(optionDescription);
    }

    if (_id) {
      castPollVote({ variables: { pollId: _id, options: votedOptions, newOptions: [] } })
        .then(() => console.log('Voted'))
        .catch((err: Error) => {
          console.error(err.message);
        });
    }
  };

  return (
    <div className={'poll-wrapper'}>
      <div className="content-wrapper">
        <div className="title-wrapper">
          <span className="title-text">{question}</span>
        </div>
        {newOptions &&
          newOptions.map((option: any, index: number) => (
            <div key={`${option.description}-${index}`} className="option-container" style={{ marginBottom: options?.length && index === options.length - 1 ? 0 : 12 }}>
              <button className="icon-wrapper" onClick={() => onVote(option.description)}>
                <i className={newOptions[index].voteMap[currentUserId] ? 'pi pi-circle-on largeMedium' : 'pi pi-circle-off largeMedium'} />
              </button>
              <div className="progress-bar-wrapper">
                <div className="title-row">
                  <span className="option-poll-title">{pollType === 'TIME' ? moment(parseInt(option.description)).format('LLL') : option.description}</span>
                  <div className="avatar-wrapper">
                    <GroupAvatar data={option?.votes} max={2} scrollable={false} />
                  </div>
                </div>
                <div className="progress-bar-background">
                  <div className="progress-bar" style={{ width: `${(option?.votes?.length / totalVotes) * 100}%` }} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default PollWidget;

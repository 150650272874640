import { useDeleteUserMutation, useGetCurrentUserLazyQuery } from '../../generate/graphql';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'primeicons/primeicons.css';
import './index.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import Error from '../../components/error';
import Modal from '../../components/modal';
import { Button } from 'primereact/button';
import { useAuth0 } from '@auth0/auth0-react';
import useLogin from '../../hooks/useLogin';
import Logo from '../../assets/logo.png';
import mixpanel from 'mixpanel-browser';

const InvitationScreen = () => {
  const login = useLogin();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const [getCurrentUser, { loading, error, data }] = useGetCurrentUserLazyQuery();
  const [submitDeletion, { loading: deletionLoading, error: deletionError }] = useDeleteUserMutation();
  const [userPendingDeletion, setUserPendingDeletion] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (data) {
      data.currentUser?.deletionPending ? setUserPendingDeletion(true) : setUserPendingDeletion(false);
    }
  }, [data]);

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentUser().catch((e) => console.log(e));
    } else {
      setModalVisible(true);
    }
  }, [isAuthenticated]);

  // Screen is loading
  if (loading || deletionLoading)
    return (
      <div className="loading-screen-wrapper">
        <ProgressSpinner className="spinner" strokeWidth="4" fill="transparent" animationDuration=".5s" />
      </div>
    );

  // Screen has error
  if (error) {
    return <Error message="Sorry, we could not find your User" buttonText="Return Home" navigate={() => navigate(`/`)} />;
  }

  if (deletionError) {
    return <Error message="There was an error while deleting your account, please email us support@livekavi.com if the error persists" buttonText="Return Home" navigate={() => navigate(`/`)} />;
  }

  return (
    <>
      <div className="deleteAccountScreen">
        <div className="header-wrapper">
          <img src={Logo} alt="Kavi Logo" className="kavi-logo" />
          <div className="title-wrapper">
            <div className="bannerBold">
              <span style={{ color: '#99D1CF' }}>K</span>
              <span style={{ color: '#E29F2C' }}>a</span>
              <span style={{ color: '#9A4327' }}>v</span>
              <span style={{ color: '#F15923' }}>i</span>
            </div>
            <div className="xxlargeRegular">Social Event Planner</div>
          </div>
        </div>

        {isAuthenticated && !userPendingDeletion && (
          <div>
            <div className="mediumBold beta-text">Are you sure you want to delete? This action is irreversible and will delete all of your data.</div>
            <div className={'button-wrapper'}>
              <Button
                id="cancelButton"
                onClick={() => {
                  navigate(`/`);
                }}
                loading={deletionLoading}
                label={'Cancel'}
              />
              <Button
                id="confirmButton"
                onClick={() => {
                  mixpanel.track('Web : Button Press - Submit Delete');
                  submitDeletion().catch((error) => console.log(error));
                }}
                loading={deletionLoading}
                label={'Confirm'}
              />
            </div>
          </div>
        )}

        {isAuthenticated && userPendingDeletion && (
          <div>
            <div className="mediumBold beta-text">Your account is pending deletion. Once Completed, you'll receive a email from us.</div>
            <div className="mediumBold beta-text">If you would like to cancel this action, please email us at support@livekavi.com</div>
          </div>
        )}
      </div>
      <Modal
        header="To Request Deletion of your account, please log in."
        body={
          <div>
            <div className="loginButtonContainer">
              <center>
                <Button
                  onClick={() => {
                    mixpanel.track('Web : Button Press - Login');
                    login();
                  }}
                >
                  Log In
                </Button>
              </center>
            </div>
          </div>
        }
        visible={!isAuthenticated && modalVisible}
        close={() => setModalVisible(false)}
      />
    </>
  );
};

export default InvitationScreen;

import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import Logo from '../../assets/logo.png';
import GooglePlay from '../../assets/google-play-badge.png';
import AppStore from '../../assets/app-store-badge.png';
import Loading from '../../components/loading';
import './index.scss';

const HomeScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [text, setText] = useState('');
  const pendingRedirect = sessionStorage.getItem('pendingRedirect');

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims()
        .then((token) => {
          if (token) sessionStorage.setItem('token', token.__raw);
        })
        .catch((err) => console.error(err));
      if (pendingRedirect !== '') {
        navigate(pendingRedirect || '');
        sessionStorage.removeItem('pendingRedirect');
      }
    }
  }, [isAuthenticated]);

  const loadInviteClick = () => {
    let inviteRef = text.trim();
    const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/[\w\/]*)*$/i;

    if (urlPattern.test(inviteRef)) {
      const parts = inviteRef.split('/');
      inviteRef = parts[parts.length - 1];
    }
    navigate(`/invitation/${inviteRef}`);
    window.location.reload();
  };

  return (
    <div className="home-screen-wrapper">
      {pendingRedirect !== null ? (
        <Loading />
      ) : (
        <>
          <div className="header-wrapper">
            <img src={Logo} alt="Kavi Logo" className="kavi-logo" />
            <div className="title-wrapper">
              <div className="bannerBold">
                <span style={{ color: '#99D1CF' }}>K</span>
                <span style={{ color: '#E29F2C' }}>a</span>
                <span style={{ color: '#9A4327' }}>v</span>
                <span style={{ color: '#F15923' }}>i</span>
              </div>
              <div className="xxlargeRegular">Social Event Planner</div>
            </div>
          </div>

          <div className="input-wrapper">
            <span className="p-input-icon-left p-inputtext-lg">
              <i className="pi pi-search largeBold" />
              <InputText value={text} onChange={(e) => setText(e.target.value)} placeholder="Invitation Link" className="input-style largeBold" />
            </span>
            <Button label="Search" className="input-button largeBold" onClick={loadInviteClick} disabled={!text || text?.length < 8} />
          </div>

          <div className="xsmallRegular beta-text">
            This is a beta web version for Kavi. Currently, you cannot create events on this web app. <br /> To create events, please download the Kavi mobile app via the Google Play or App Store
          </div>

          <div className="button-wrapper">
            <a href={'https://apps.apple.com/us/app/kavi-social-event-organizer/id6444243719'} target="_blank">
              <img src={AppStore} alt="App Store Link" height={68} className="clickable" />
            </a>
            <a href={'https://play.google.com/store/apps/details?id=com.kavimobile'} target="_blank">
              <img src={GooglePlay} alt="Google Play Link" height={100} className="clickable" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeScreen;

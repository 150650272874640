// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .avatar-list-wrapper .avatar-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid var(--gray-light-color); }
    .avatar-list-wrapper .avatar-wrapper .avatar-image {
      display: flex; }
    .avatar-list-wrapper .avatar-wrapper .avatar-name-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: var(--spacing-md); }
      .avatar-list-wrapper .avatar-wrapper .avatar-name-wrapper .avatar-username {
        display: flex; }
      .avatar-list-wrapper .avatar-wrapper .avatar-name-wrapper .avatar-username {
        display: flex; }
  .avatar-list-wrapper .last-item {
    padding-bottom: 0px;
    border-bottom: none; }
`, "",{"version":3,"sources":["webpack://./src/components/guestList/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B,EAAA;EAH7B;IAMI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,gDAAgD,EAAA;IAVpD;MAaM,aAAa,EAAA;IAbnB;MAiBM,aAAa;MACb,sBAAsB;MACtB,uBAAuB;MACvB,8BAA8B,EAAA;MApBpC;QAuBQ,aAAa,EAAA;MAvBrB;QA0BQ,aAAa,EAAA;EA1BrB;IAgCI,mBAAmB;IACnB,mBAAmB,EAAA","sourcesContent":[".avatar-list-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n\n  .avatar-wrapper {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    padding: 10px;\n    border-bottom: 1px solid var(--gray-light-color);\n\n    .avatar-image {\n      display: flex;\n    }\n\n    .avatar-name-wrapper {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      margin-left: var(--spacing-md);\n\n      .avatar-username {\n        display: flex;\n      }\n      .avatar-username {\n        display: flex;\n      }\n    }\n  }\n\n  .last-item {\n    padding-bottom: 0px;\n    border-bottom: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

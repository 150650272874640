import { NoteWidgetFieldsFragment, PollWidgetFieldsFragment, useGetInvitationQuery } from '../../generate/graphql';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'primeicons/primeicons.css';
import './index.scss';
import GuestList from '../../components/guestList';
import Rsvp from '../../components/rsvp';
import { ProgressSpinner } from 'primereact/progressspinner';
import ParseText from '../../components/parseText';
import Error from '../../components/error';
import Modal from '../../components/modal';
import { Button } from 'primereact/button';
import { useAuth0 } from '@auth0/auth0-react';
import GooglePlay from '../../assets/google-play-badge.png';
import AppStore from '../../assets/app-store-badge.png';
import useLogin from '../../hooks/useLogin';
import { Messages } from 'primereact/messages';
import mixpanel from 'mixpanel-browser';
import ChatScreen from '../ChatScreen';
import NoteWidget from '../../components/noteWidget';
import PollWidget from '../../components/pollWidget';

const dateFormat = 'MMMM Do, h:mma';

const InvitationScreen = () => {
  const login = useLogin();
  const messages = useRef<Messages>(null);
  const navigate = useNavigate();
  const { invitationCode } = useParams();
  const { isAuthenticated } = useAuth0();

  // const { loading, error, data, refetch } = useGetInvitationQuery({ variables: { inviteRef: invitationCode || '' } });
  const { loading, error, data, refetch } = useGetInvitationQuery({ variables: { inviteRef: invitationCode || '' } });

  const [modalVisible, setModalVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [noteWigets, setNoteWidgets] = useState<NoteWidgetFieldsFragment[]>([]);
  const [pollWidgets, setPollWidgets] = useState<PollWidgetFieldsFragment[]>([]);
  const [eventId, setEventId] = useState<string>('');
  const fakeGuestListData = [
    {
      _id: '1',
      nickname: 'Mr. UnBlurrred',
      name: 'Mr. UnBlurrred',
      picture: 'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExbndsa3pwcjJqYmN2dGllMjN2cHhoY2lhbTNwajJkMmtneHM5eXkydSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/StKiS6x698JAl9d6cx/giphy.gif',
      friends: [],
      incomingFriendRequests: [],
      outgoingFriendRequests: [],
    },
    {
      _id: '2',
      nickname: 'Unblurry Mess',
      name: 'Unblurry Mess',
      picture: 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExdmxpb2lqMDgydjBuZmJscDYyNmc3Mnhkczk0Y3Y1NmI0bWRmd3BnbiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l4pTfx2qLszoacZRS/giphy.gif',
      friends: [],
      incomingFriendRequests: [],
      outgoingFriendRequests: [],
    },
  ];

  mixpanel.track_links('#googleLink', 'Web - Link Click : Google Play Store');
  mixpanel.track_links('#appleLink', 'Web - Link Click : Apple App Store');

  useEffect(() => {
    messages.current?.show({
      content: (
        <div className="messageContainer">
          <div className="messageText">
            Download our mobile app to get the full experience with plenty of other features!
            <a style={{ color: 'white' }} href="https://google.com">
              {' '}
              Click here!
            </a>
          </div>
          <button
            style={{ backgroundColor: 'transparent', border: 'none' }}
            onClick={() => {
              messages.current?.clear();
            }}
          >
            <i className="pi pi-times largeMedium" />
          </button>
        </div>
      ),
      closable: false,
      sticky: true,
    });
    if (data?.invitation?.widgets) {
      const noteWidgets = data?.invitation?.widgets.filter((widget) => widget?.__typename === 'NoteWidget');
      setNoteWidgets(noteWidgets as NoteWidgetFieldsFragment[]);

      const pollWidgets = data?.invitation?.widgets.filter((widget) => widget?.__typename === 'PollWidget');
      setPollWidgets(pollWidgets as PollWidgetFieldsFragment[]);
    }

    if (data?.invitation?._id) {
      setEventId(data.invitation._id);
    }
  }, [data?.invitation]);

  const checkIfAuthenticated = () => {
    if (!isAuthenticated) {
      setModalVisible(true);
      return false;
    }
    return true;
  };

  // Screen is loading
  if (loading)
    return (
      <div className="loading-screen-wrapper">
        <ProgressSpinner className="spinner" strokeWidth="4" fill="transparent" animationDuration=".5s" />
      </div>
    );

  // Screen has error
  if (error) {
    return <Error message="Sorry, we could not find your Invitation. Please double check you entered the right code and try again!" buttonText="Return Home" navigate={() => navigate(`/`)} />;
  }

  return (
    <>
      <div className="invitationScreen">
        {showMessage && (
          <div className="messageContainer">
            <div className="messageText">
              Download our mobile app to get the full experience with plenty of other features...
              <button className="downloadModal smallBold" onClick={() => setDownloadModalVisible(true)}>
                {' '}
                Click here!
              </button>
            </div>
            <button
              style={{ backgroundColor: 'transparent', border: 'none' }}
              onClick={() => {
                setShowMessage(false);
              }}
            >
              <i className="pi pi-times largeMedium" />
            </button>
          </div>
        )}
        <div className="titleContainer">
          <div className="titleImage">
            <img className="image" src={`https://kavi-s3-public.s3.us-west-1.amazonaws.com/images/${(data?.invitation?.image as string) ?? ''}`} />
          </div>
          <div className="titleDetails">
            <div className="details-title largeBold">{data?.invitation?.name}</div>
            <div className="dates smallRegular">
              {data?.invitation?.startTime && data?.invitation?.endTime
                ? `${moment.unix(Number(data?.invitation?.startTime) / 1000).format(dateFormat)} - ${moment.unix(Number(data?.invitation?.endTime) / 1000).format(dateFormat)}`
                : 'Host has not set a date and time yet'}
            </div>
            <div className="details-title largeBold">Details</div>
            <div className="detail-item">
              <i className="pi pi-clock smallRegular" />
              <div className="smallRegular">
                {data?.invitation?.startTime && data?.invitation?.endTime
                  ? `${moment.unix(Number(data?.invitation?.startTime) / 1000) > moment() ? 'Starts ' : ''}${moment.unix(Number(data?.invitation?.endTime) / 1000).fromNow()}`
                  : 'TBD'}
              </div>
            </div>
            <div className="detail-item-location">
              <i className="pi pi-map-marker smallRegular" />
              {!isAuthenticated && (
                <div className="overlay-text-location" onClick={login}>
                  Login to view
                </div>
              )}
              {isAuthenticated ? (
                <div className="smallRegular">{data?.invitation?.location?.name || 'Host has not set a location yet'}</div>
              ) : (
                <div className="blurred">
                  <div className="smallRegular">Cool, you know how to unblur.. but please Login to view</div>
                </div>
              )}
            </div>
            <div className="detail-item">
              <i className="pi pi-user smallRegular" />
              <div className="smallRegular">Hosts: {[data?.invitation?.host?.name || ''].concat((data?.invitation?.additionalHosts ?? []).map((host) => host?.name || '').join(', '))}</div>
            </div>
          </div>
        </div>
        <div className="bodyContainer">
          <div className="bodyLeftSideContainer">
            <ChatScreen />
          </div>
          <div className="bodyRightSideContainer">
            {data?.invitation?.description && (
              <div className="section-wrapper" style={{ display: 'block' }}>
                <div className="section-title largeBold">Description</div>
                <div className="smallRegular">
                  <ParseText text={data?.invitation?.description || ''} />
                </div>
              </div>
            )}

            <div className={!isAuthenticated ? 'blurred' : ''}>
              {noteWigets.length > 0 &&
                noteWigets.map((widget) =>
                  isAuthenticated ? <NoteWidget title={widget.title ?? ''} body={widget.body ?? ''} /> : <NoteWidget title="Probably a very important note..!" body="Login to view it" />,
                )}
              {isAuthenticated && pollWidgets.length > 0 && pollWidgets.map((widget) => <PollWidget {...widget} eventId={eventId} />)}
            </div>
            <div className="section-wrapper">
              <div className="section-title largeBold">RSVP</div>
              <div id="descriptionBody">
                <Rsvp
                  inviteRef={data?.invitation?.inviteReferral || ''}
                  checkIfAuthenticated={checkIfAuthenticated}
                  refetch={() => {
                    refetch().catch((e) => console.log(e));
                  }}
                />
              </div>
            </div>

            <div className='section-wrapper'>
              <div className='section-title largeBold'>Guest List</div>
              {!isAuthenticated && (
                <div className='overlay-text'>
                  <Button
                    className='blur-login-button'
                    onClick={() => {
                      mixpanel.track('Web : Button Press - Login');
                      login();
                    }}
                  >
                    Login to view the Guest list
                  </Button>
                </div>
              )}
              <div className={!isAuthenticated ? 'blurred' : ''}>
                <GuestList
                  attending={isAuthenticated ? data?.invitation?.attending || [] : fakeGuestListData}
                  notAttending={isAuthenticated ? data?.invitation?.notAttending || [] : fakeGuestListData}
                  invited={isAuthenticated ? data?.invitation?.invited || [] : fakeGuestListData}
                  maybeAttending={isAuthenticated ? data?.invitation?.maybeAttending || [] : fakeGuestListData}
                ></GuestList>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        header='Download our Mobile App'
        body={
          <div>
            <center>
              Experince the full features of Kavi through the app <br />
              <>
                <a id='googleLink' href='https://play.google.com/store/apps/details?id=com.kavimobile'>
                  <img src={GooglePlay} className='modalAndroidAppIcon' />
                </a>
                <br />
                <a id="appleLink" href="https://apps.apple.com/us/app/kavi-social-event-organizer/id6444243719">
                  <img src={AppStore} className="modalAppleAppIcon" />
                </a>
              </>
              <br />
              <br />
            </center>
          </div>
        }
        visible={downloadModalVisible}
        close={() => setDownloadModalVisible(false)}
      />
      <Modal
        header="Login to RSVP"
        body={
          <div>
            {/*<>*/}
            {/*  <center>*/}
            {/*    Experince the full features of Kavi through the app <br />*/}
            {/*    <>*/}
            {/*      <a href="https://play.google.com/store/apps/details?id=com.kavimobile">*/}
            {/*        <img src={GooglePlay} className="modalAndroidAppIcon" />*/}
            {/*      </a>*/}
            {/*      <br />*/}
            {/*      <a href="https://apps.apple.com/us/app/kavi-social-event-organizer/id6444243719">*/}
            {/*        <img src={AppStore} className="modalAppleAppIcon" />*/}
            {/*      </a>*/}
            {/*    </>*/}
            {/*    <br />*/}
            {/*    <br />*/}
            {/*    <i>*/}
            {/*      <b>or..</b>*/}
            {/*    </i>*/}
            {/*    <br />*/}
            {/*    <br />*/}
            {/*    Login to RSVP through the site <br />*/}
            {/*    <br />*/}
            {/*  </center>*/}
            {/*</>*/}
            <div className="loginButtonContainer">
              <center>
                <Button
                  onClick={() => {
                    login();
                  }}
                >
                  Log In
                </Button>
              </center>
            </div>
          </div>
        }
        visible={!isAuthenticated && modalVisible}
        close={() => setModalVisible(false)}
      />
    </>
  );
};

export default InvitationScreen;

import { useAuth0 } from '@auth0/auth0-react';

const useLogin = () => {
  const { loginWithRedirect } = useAuth0();

  return () => {
    if (window.location.href.split('/').includes('invitation')) {
      const urlElem = window.location.href.split('/');
      const index = urlElem.indexOf('invitation');
      sessionStorage.setItem('pendingRedirect', '/' + urlElem[index] + '/' + urlElem[index + 1]);
    }
    if (window.location.href.split('/').includes('chat')) {
      const urlElem = window.location.href.split('/');
      const index = urlElem.indexOf('chat');
      sessionStorage.setItem('pendingRedirect', '/' + urlElem[index] + '/' + urlElem[index + 1]);
    }
    loginWithRedirect({
      appState: { returnTo: window.location.href },
    }).catch((err) => console.log(err));
  };
};

export default useLogin;

import { ProgressSpinner } from 'primereact/progressspinner';

const Loading = () => {
  return (
    <>
      <div className="loading-screen-wrapper">
        <ProgressSpinner className="spinner" strokeWidth="4" fill="transparent" animationDuration=".5s" />
      </div>
    </>
  );
};
export default Loading;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#goingButton {
  margin: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) 0px;
  background: #99d2d0; }

#maybeButton {
  margin: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) 0px;
  background: #e29f2e;
  border: 1px solid #e29f2e; }

#notGoingButton {
  margin: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) 0px;
  background: #bababa;
  border: 1px solid #bababa; }
`, "",{"version":3,"sources":["webpack://./src/components/rsvp/index.scss"],"names":[],"mappings":"AAAA;EACE,iEAAiE;EACjE,mBAAmB,EAAA;;AAGrB;EACE,iEAAiE;EACjE,mBAAmB;EACnB,yBAAyB,EAAA;;AAG3B;EACE,iEAAiE;EACjE,mBAAmB;EACnB,yBAAyB,EAAA","sourcesContent":["#goingButton {\n  margin: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) 0px;\n  background: #99d2d0;\n}\n\n#maybeButton {\n  margin: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) 0px;\n  background: #e29f2e;\n  border: 1px solid #e29f2e;\n}\n\n#notGoingButton {\n  margin: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) 0px;\n  background: #bababa;\n  border: 1px solid #bababa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

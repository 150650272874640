import React from 'react';
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import routes from './routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import client from './services/apolloClient';
import { PrimeReactProvider } from 'primereact/api';
import { Auth0Provider } from '@auth0/auth0-react';
import { ApolloProvider } from '@apollo/client';
import mixpanel from 'mixpanel-browser';

// Instantiate your chat client *outside of your component* with the API key
const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_CHAT_API_KEY || '');

function App() {
  const router = createBrowserRouter(routes);
  // Ideally, you would want to do this setup in an effect or similar setup flow
  // and not render the rest of your app until the connection is established.

  if (!process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN) {
    throw new Error('MIX_PANEL_PROJECT_TOKEN is not defined');
  }

  mixpanel.init(process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN);
  mixpanel.register({ platform: 'Web' });
  mixpanel.track_pageview();

  return (
    <ApolloProvider client={client}>
      <PrimeReactProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_DOMAIN || ''}
          clientId={process.env.REACT_APP_CLIENT_ID || ''}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <Chat client={chatClient} darkMode={true}>
            <RouterProvider router={router} />
          </Chat>
        </Auth0Provider>
      </PrimeReactProvider>
    </ApolloProvider>
  );
}

export default App;

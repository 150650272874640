// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  display: flex;
  margin-right: 10px; }

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.username {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px; }

.nickname {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; }
`, "",{"version":3,"sources":["webpack://./src/components/clickableAvatar/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB,EAAA;;AAEpB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;;AAErB;EACI,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,eAAe,EAAA;;AAEnB;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB,EAAA","sourcesContent":[".avatar {\n  display: flex;\n  margin-right: 10px;\n}\n.modalWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.username {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin-bottom: 10px;\n    font-size: 20px;\n}\n.nickname {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

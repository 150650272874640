import { ModalProps } from './types';
import { Dialog } from 'primereact/dialog';
import './index.scss';
/* eslint-disable */

const Modal = ({ header, body, visible, close, closeable = true, showHeader = true, dismissableMask = false }: ModalProps) => {
  return (
    <Dialog
      className="dialog"
      header={header}
      visible={visible}
      onHide={() => close()}
      resizable={false}
      draggable={false}
      closable={closeable}
      showHeader={showHeader}
      dismissableMask={dismissableMask}
    >
      {body}
    </Dialog>
  );
};
export default Modal;
/* eslint-enable */

import HomeScreen from '../screens/HomeScreen';
import ErrorScreen from '../screens/ErrorScreen';
import InvitationScreen from '../screens/InvitationScreen';
import EventDetailScreen from '../screens/EventDetailScreen';
import Navbar from '../components/navbar';
import React from 'react';
import DeleteAccountScreen from '../screens/deleteAccountScreen';
import ChatScreen from '../screens/ChatScreen';

const routes = [
  {
    path: '/',
    element: (
      <>
        <Navbar />
        <HomeScreen />
      </>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/account/delete',
    element: (
      <>
        <Navbar />
        <DeleteAccountScreen />
      </>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/chat/:invitationCode',
    element: (
      <>
        <ChatScreen />
      </>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/invitation/:invitationCode',
    element: (
      <>
        <Navbar />
        <InvitationScreen />
      </>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/event/:eventId',
    element: (
      <>
        <Navbar />
        <EventDetailScreen />
      </>
    ),
    errorElement: <ErrorScreen />,
  },
];

export default routes;

import { Button } from 'primereact/button';
import { rsvpStatus } from './types';
import { useRef, useState } from 'react';
import { InvitationResponse, useInvitationResponseMutation } from '../../generate/graphql';
import './index.scss';

import { Toast } from 'primereact/toast';
import mixpanel from 'mixpanel-browser';

const Rsvp = ({ inviteRef, refetch, checkIfAuthenticated }: { inviteRef: string; refetch?: () => void; checkIfAuthenticated: () => boolean }) => {
  const [invitationResponseMutation] = useInvitationResponseMutation();
  const toast = useRef<Toast>(null);

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [maybeLoading, setMaybeLoading] = useState(false);

  const onPress = async (RsvpType: rsvpStatus) => {
    if (!checkIfAuthenticated()) {
      return;
    }

    switch (RsvpType) {
      case rsvpStatus.attending:
        setAcceptLoading(true);
        await invitationResponseMutation({ variables: { inviteRef, response: InvitationResponse.Accepted } }).catch((err: Error) => {
          setAcceptLoading(false);
          console.log(err);
        });
        if (refetch) refetch();

        toast.current?.show({ severity: 'success', summary: 'Thanks!', detail: "You've RSVP'd for this event!" });
        setAcceptLoading(false);
        break;
      case rsvpStatus.maybeAttending:
        setMaybeLoading(true);
        await invitationResponseMutation({ variables: { inviteRef, response: InvitationResponse.Maybe } }).catch((err: Error) => {
          setMaybeLoading(false);
          console.log(err);
        });
        if (refetch) refetch();
        toast.current?.show({ severity: 'success', summary: 'Thanks!', detail: "You've RSVP'd for this event!" });
        setMaybeLoading(false);
        break;
      case rsvpStatus.notAttending:
        setDeclineLoading(true);
        await invitationResponseMutation({ variables: { inviteRef, response: InvitationResponse.Declined } }).catch((err: Error) => {
          setDeclineLoading(false);
          console.log(err);
        });
        if (refetch) refetch();
        toast.current?.show({ severity: 'success', summary: 'Thanks!', detail: "You've RSVP'd for this event!" });
        setDeclineLoading(false);
        break;
    }
  };

  return (
    <div>
      <Button
        id="goingButton"
        onClick={() => {
          mixpanel.track('Web : Button Press - RSVP - Accept');
          onPress(rsvpStatus.attending).catch((error) => console.log(error));
        }}
        loading={acceptLoading}
        label={'Going'}
      />
      <Button
        id="maybeButton"
        onClick={() => {
          mixpanel.track('Web : Button Press - RSVP - Maybe');
          onPress(rsvpStatus.maybeAttending).catch((error) => console.log(error));
        }}
        loading={maybeLoading}
        label={'Maybe'}
      />
      <Button
        id="notGoingButton"
        onClick={() => {
          mixpanel.track('Web : Button Press - RSVP - Decline');
          onPress(rsvpStatus.notAttending).catch((error) => console.log(error));
        }}
        loading={declineLoading}
        label={'Not Going'}
      />
      <Toast ref={toast} />
    </div>
  );
};
export default Rsvp;

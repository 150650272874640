// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-wrapper .title-wrapper {
  flex-direction: row;
  margin-bottom: calc(var(--spacing-xs));
  align-items: center; }

.note-wrapper .title-text {
  font-size: var(--font-lg);
  font-weight: bold;
  color: white;
  flex: 1 1; }

.note-wrapper .body-wrapper {
  border-radius: 16px;
  border-color: #6E6E73;
  border-width: 1px;
  padding: calc(var(--spacing-sm)); }
`, "",{"version":3,"sources":["webpack://./src/components/noteWidget/index.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAmB;EACnB,sCAAsC;EACtC,mBAAmB,EAAA;;AAJvB;EAOI,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;EACZ,SAAO,EAAA;;AAVX;EAcI,mBAAmB;EACnB,qBAAqB;EACrB,iBAAiB;EACjB,gCAAgC,EAAA","sourcesContent":[".note-wrapper {\n  .title-wrapper {\n    flex-direction: row;\n    margin-bottom: calc(var(--spacing-xs));\n    align-items: center;\n  }\n  .title-text {\n    font-size: var(--font-lg);\n    font-weight: bold;\n    color: white;\n    flex: 1;\n  }\n\n  .body-wrapper {\n    border-radius: 16px;\n    border-color: #6E6E73;\n    border-width: 1px;\n    padding: calc(var(--spacing-sm));\n  }\n  .body-text {\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

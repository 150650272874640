import { ParseTextProps } from './types';
import './index.scss';

/** Used for parsing links in text */
const ParseText = ({ text = '' }: ParseTextProps) => {
  // Logic for URL Parsing
  const parseTextForLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part) => {
      if (part.match(urlRegex)) {
        return (
          <a className="link clickable" href={part} target="_blank">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return <span>{parseTextForLinks(text)}</span>;
};
export default ParseText;

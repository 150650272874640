import { ErrorProps } from './types';
import { Button } from 'primereact/button';
import './index.scss';

const Error = ({ message, buttonText, navigate }: ErrorProps) => {
  return (
    <div className="error-screen-wrapper">
      <div className="message largeRegular">{message}</div>
      <Button label={buttonText} className="button largeBold" onClick={navigate} />
    </div>
  );
};
export default Error;

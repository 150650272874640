// auth0Client.js
import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0Client = new Auth0Client({
  domain: process.env.REACT_APP_DOMAIN || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
});

export default auth0Client;

import { Avatar } from 'primereact/avatar';
import { UserBasicFieldsFragment } from '../../generate/graphql';
import './index.scss';
import { determineImageUrl } from '../../utils/determineImageUrl';
import { useState } from 'react';
import Modal from '../modal';

const clickableAvatar = (props: UserBasicFieldsFragment) => {
  const { name, nickname, picture } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button onClick={() => setShowModal(!showModal)} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer', alignSelf: 'center' }}>
        <Avatar image={determineImageUrl(picture)} size="large" shape="circle" className="avatar" />
      </button>

      <Modal
        header={'Profile'}
        body={
          <div className="modalWrapper">
            <Avatar image={determineImageUrl(picture)} size="xlarge" shape="circle" className="avatar" />
            <div className="userInfoContainer">
              <div className="username">Name: {name}</div>
              <div className="nickname">Username: {`@${nickname ?? ''}`}</div>
            </div>
          </div>
        }
        visible={showModal}
        close={() => setShowModal(false)}
        dismissableMask={true}
      />
    </>
  );
};
export default clickableAvatar;

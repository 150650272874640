import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type Event = {
  __typename?: 'Event';
  _id?: Maybe<Scalars['ID']['output']>;
  additionalHosts?: Maybe<Array<Maybe<User>>>;
  attending?: Maybe<Array<Maybe<User>>>;
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  host?: Maybe<User>;
  image?: Maybe<Scalars['String']['output']>;
  inviteReferral?: Maybe<Scalars['String']['output']>;
  invited?: Maybe<Array<Maybe<User>>>;
  location?: Maybe<Location>;
  maybeAttending?: Maybe<Array<Maybe<User>>>;
  name?: Maybe<Scalars['String']['output']>;
  notAttending?: Maybe<Array<Maybe<User>>>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  streamcChannelID?: Maybe<Scalars['String']['output']>;
  widgets?: Maybe<Array<Maybe<Widgets>>>;
};

export type Gps = {
  __typename?: 'GPS';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['Float']['output']>;
};

export type GpsDisplay = {
  __typename?: 'GPSDisplay';
  _id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  shareLocation?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GpSsubmissionResult = {
  __typename?: 'GPSsubmissionResult';
  shouldStop?: Maybe<Scalars['Boolean']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum InvitationResponse {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Maybe = 'MAYBE',
}

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']['output']>;
  coordinate?: Maybe<Coordinate>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptFriendRequest?: Maybe<User>;
  acceptInvite?: Maybe<Event>;
  addAdditionalHost?: Maybe<Event>;
  cancelFriendRequest?: Maybe<User>;
  castPollVote?: Maybe<PollWidget>;
  createEvent?: Maybe<Event>;
  createNote?: Maybe<Event>;
  createPoll?: Maybe<Event>;
  deleteEvent?: Maybe<Scalars['Boolean']['output']>;
  deleteNote?: Maybe<Event>;
  deletePoll?: Maybe<Event>;
  deleteUser?: Maybe<User>;
  editEvent?: Maybe<Event>;
  editLocationSharing?: Maybe<Scalars['Boolean']['output']>;
  editNote?: Maybe<NoteWidget>;
  editPoll?: Maybe<PollWidget>;
  editUser?: Maybe<User>;
  ignoreFriendRequest?: Maybe<User>;
  invitationResponse?: Maybe<Event>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  rejectInvite?: Maybe<Event>;
  removeAdditionalHost?: Maybe<Event>;
  removeFriend?: Maybe<User>;
  sendFriendRequest?: Maybe<User>;
  sendFriendRequestByUsername?: Maybe<User>;
  sendInvites?: Maybe<Event>;
  submitGPS?: Maybe<GpSsubmissionResult>;
};

export type MutationAcceptFriendRequestArgs = {
  senderId: Scalars['ID']['input'];
};

export type MutationAcceptInviteArgs = {
  inviteRef: Scalars['String']['input'];
};

export type MutationAddAdditionalHostArgs = {
  additionalHostId: Scalars['ID']['input'];
  eventId: Scalars['ID']['input'];
};

export type MutationCancelFriendRequestArgs = {
  receiverId: Scalars['ID']['input'];
};

export type MutationCastPollVoteArgs = {
  newOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pollId: Scalars['String']['input'];
};

export type MutationCreateEventArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  locationAddress?: InputMaybe<Scalars['String']['input']>;
  locationLatitude?: InputMaybe<Scalars['Float']['input']>;
  locationLongitude?: InputMaybe<Scalars['Float']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationCreateNoteArgs = {
  body: Scalars['String']['input'];
  eventId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreatePollArgs = {
  eventId: Scalars['ID']['input'];
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  placement?: InputMaybe<Scalars['String']['input']>;
  pollType?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteEventArgs = {
  eventId: Scalars['String']['input'];
};

export type MutationDeleteNoteArgs = {
  eventId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
};

export type MutationDeletePollArgs = {
  eventId: Scalars['ID']['input'];
  pollId: Scalars['ID']['input'];
};

export type MutationEditEventArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  eventId: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  locationAddress?: InputMaybe<Scalars['String']['input']>;
  locationLatitude?: InputMaybe<Scalars['Float']['input']>;
  locationLongitude?: InputMaybe<Scalars['Float']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationEditLocationSharingArgs = {
  eventId: Scalars['String']['input'];
  shareLocation: Scalars['Boolean']['input'];
};

export type MutationEditNoteArgs = {
  body: Scalars['String']['input'];
  noteId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditPollArgs = {
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pollId: Scalars['String']['input'];
  pollType?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditUserArgs = {
  name: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationIgnoreFriendRequestArgs = {
  senderId: Scalars['ID']['input'];
};

export type MutationInvitationResponseArgs = {
  inviteRef: Scalars['String']['input'];
  response: InvitationResponse;
};

export type MutationRejectInviteArgs = {
  inviteRef: Scalars['String']['input'];
};

export type MutationRemoveAdditionalHostArgs = {
  additionalHostId: Scalars['ID']['input'];
  eventId: Scalars['ID']['input'];
};

export type MutationRemoveFriendArgs = {
  userIdToRemove: Scalars['ID']['input'];
};

export type MutationSendFriendRequestArgs = {
  receiverId: Scalars['ID']['input'];
};

export type MutationSendFriendRequestByUsernameArgs = {
  username: Scalars['String']['input'];
};

export type MutationSendInvitesArgs = {
  eventId: Scalars['String']['input'];
  invitees: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationSubmitGpsArgs = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  timestamp: Scalars['Float']['input'];
};

export type NoteWidget = {
  __typename?: 'NoteWidget';
  _id?: Maybe<Scalars['ID']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PollOption = {
  __typename?: 'PollOption';
  description?: Maybe<Scalars['String']['output']>;
  votes?: Maybe<Array<Maybe<User>>>;
};

export type PollWidget = {
  __typename?: 'PollWidget';
  _id?: Maybe<Scalars['ID']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Array<Maybe<PollOption>>>;
  placement?: Maybe<Scalars['String']['output']>;
  pollType?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  allEvents?: Maybe<Array<Maybe<Event>>>;
  currentUser?: Maybe<User>;
  event?: Maybe<Event>;
  eventByObjId?: Maybe<Event>;
  eventBySCId?: Maybe<Event>;
  getGPSData?: Maybe<Array<Maybe<GpsDisplay>>>;
  getLatestVersion?: Maybe<Scalars['String']['output']>;
  invitation?: Maybe<Event>;
  isUsernameAvailable?: Maybe<Scalars['Boolean']['output']>;
  searchByUsername?: Maybe<Array<Maybe<User>>>;
  user?: Maybe<User>;
  userByUsername?: Maybe<User>;
  usersByObjId?: Maybe<Array<Maybe<User>>>;
};

export type QueryEventArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEventByObjIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryEventByScIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetGpsDataArgs = {
  eventId: Scalars['String']['input'];
};

export type QueryInvitationArgs = {
  inviteRef: Scalars['String']['input'];
};

export type QueryIsUsernameAvailableArgs = {
  username: Scalars['String']['input'];
};

export type QuerySearchByUsernameArgs = {
  search: Scalars['String']['input'];
};

export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryUserByUsernameArgs = {
  username: Scalars['String']['input'];
};

export type QueryUsersByObjIdArgs = {
  id: Array<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']['output']>;
  auth0_id?: Maybe<Scalars['String']['output']>;
  deletionPending?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  friends?: Maybe<Array<Maybe<User>>>;
  gps?: Maybe<Gps>;
  incomingFriendRequests?: Maybe<Array<Maybe<User>>>;
  latestLogin?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  outgoingFriendRequests?: Maybe<Array<Maybe<User>>>;
  picture?: Maybe<Scalars['String']['output']>;
  signUpDate?: Maybe<Scalars['String']['output']>;
  streamcToken?: Maybe<Scalars['String']['output']>;
  streamcUsername?: Maybe<Scalars['String']['output']>;
};

export type Widgets = NoteWidget | PollWidget;

export type EventBasicFieldsFragment = {
  __typename?: 'Event';
  _id?: string | null;
  name?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  image?: string | null;
  description?: string | null;
  streamcChannelID?: string | null;
  location?: { __typename?: 'Location'; name?: string | null; address?: string | null; coordinate?: { __typename?: 'Coordinate'; longitude?: number | null; latitude?: number | null } | null } | null;
};

export type EventAllFieldsFragment = {
  __typename?: 'Event';
  _id?: string | null;
  name?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  image?: string | null;
  description?: string | null;
  streamcChannelID?: string | null;
  inviteReferral?: string | null;
  location?: { __typename?: 'Location'; name?: string | null; address?: string | null; coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null } | null;
  host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
  additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
};

export type UserBasicFieldsFragment = {
  __typename?: 'User';
  _id?: string | null;
  name?: string | null;
  email?: string | null;
  nickname?: string | null;
  picture?: string | null;
  auth0_id?: string | null;
  streamcUsername?: string | null;
  streamcToken?: string | null;
  deletionPending?: boolean | null;
};

export type UserAllFieldsFragment = {
  __typename?: 'User';
  _id?: string | null;
  name?: string | null;
  email?: string | null;
  nickname?: string | null;
  picture?: string | null;
  auth0_id?: string | null;
  streamcUsername?: string | null;
  streamcToken?: string | null;
  deletionPending?: boolean | null;
  friends?: Array<{
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
  } | null> | null;
  outgoingFriendRequests?: Array<{
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
  } | null> | null;
  incomingFriendRequests?: Array<{
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
  } | null> | null;
};

export type NoteWidgetFieldsFragment = { __typename?: 'NoteWidget'; _id?: string | null; title?: string | null; body?: string | null };

export type PollWidgetFieldsFragment = {
  __typename?: 'PollWidget';
  _id?: string | null;
  question?: string | null;
  pollType?: string | null;
  type?: string | null;
  hidden?: boolean | null;
  options?: Array<{
    __typename?: 'PollOption';
    description?: string | null;
    votes?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null> | null;
};

type WidgetFields_NoteWidget_Fragment = { __typename?: 'NoteWidget'; _id?: string | null; title?: string | null; body?: string | null };

type WidgetFields_PollWidget_Fragment = {
  __typename?: 'PollWidget';
  _id?: string | null;
  question?: string | null;
  pollType?: string | null;
  type?: string | null;
  hidden?: boolean | null;
  options?: Array<{
    __typename?: 'PollOption';
    description?: string | null;
    votes?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null> | null;
};

export type WidgetFieldsFragment = WidgetFields_NoteWidget_Fragment | WidgetFields_PollWidget_Fragment;

export type AddAdditionalHostMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
  additionalHostId: Scalars['ID']['input'];
}>;

export type AddAdditionalHostMutation = {
  __typename?: 'Mutation';
  addAdditionalHost?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null;
};

export type CreateEventMutationVariables = Exact<{
  description?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  locationAddress?: InputMaybe<Scalars['String']['input']>;
  locationLongitude?: InputMaybe<Scalars['Float']['input']>;
  locationLatitude?: InputMaybe<Scalars['Float']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateEventMutation = {
  __typename?: 'Mutation';
  createEvent?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null } | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
  } | null;
};

export type EditEventMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  locationAddress?: InputMaybe<Scalars['String']['input']>;
  locationLongitude?: InputMaybe<Scalars['Float']['input']>;
  locationLatitude?: InputMaybe<Scalars['Float']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
}>;

export type EditEventMutation = {
  __typename?: 'Mutation';
  editEvent?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; longitude?: number | null; latitude?: number | null } | null;
    } | null;
  } | null;
};

export type EditLocationSharingMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  shareLocation: Scalars['Boolean']['input'];
}>;

export type EditLocationSharingMutation = { __typename?: 'Mutation'; editLocationSharing?: boolean | null };

export type EditUserMutationVariables = Exact<{
  name: Scalars['String']['input'];
  username: Scalars['String']['input'];
}>;

export type EditUserMutation = {
  __typename?: 'Mutation';
  editUser?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
  } | null;
};

export type AcceptInviteMutationVariables = Exact<{
  inviteRef: Scalars['String']['input'];
}>;

export type AcceptInviteMutation = { __typename?: 'Mutation'; acceptInvite?: { __typename?: 'Event'; _id?: string | null } | null };

export type InvitationResponseMutationVariables = Exact<{
  inviteRef: Scalars['String']['input'];
  response: InvitationResponse;
}>;

export type InvitationResponseMutation = { __typename?: 'Mutation'; invitationResponse?: { __typename?: 'Event'; _id?: string | null } | null };

export type RejectInviteMutationVariables = Exact<{
  inviteRef: Scalars['String']['input'];
}>;

export type RejectInviteMutation = {
  __typename?: 'Mutation';
  rejectInvite?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null;
};

export type SendInvitesMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  invitees: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type SendInvitesMutation = {
  __typename?: 'Mutation';
  sendInvites?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null;
};

export type RemoveAdditionalHostMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
  additionalHostId: Scalars['ID']['input'];
}>;

export type RemoveAdditionalHostMutation = {
  __typename?: 'Mutation';
  removeAdditionalHost?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null;
};

export type CreateNoteMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  body: Scalars['String']['input'];
}>;

export type CreateNoteMutation = {
  __typename?: 'Mutation';
  createNote?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null;
};

export type DeleteNoteMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
}>;

export type DeleteNoteMutation = {
  __typename?: 'Mutation';
  deleteNote?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    status?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    widgets?: Array<
      | { __typename?: 'NoteWidget'; _id?: string | null; title?: string | null; body?: string | null }
      | {
          __typename?: 'PollWidget';
          _id?: string | null;
          question?: string | null;
          pollType?: string | null;
          type?: string | null;
          hidden?: boolean | null;
          options?: Array<{
            __typename?: 'PollOption';
            description?: string | null;
            votes?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
          } | null> | null;
        }
      | null
    > | null;
  } | null;
};

export type EditNoteMutationVariables = Exact<{
  noteId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  body: Scalars['String']['input'];
}>;

export type EditNoteMutation = { __typename?: 'Mutation'; editNote?: { __typename?: 'NoteWidget'; _id?: string | null; title?: string | null; body?: string | null } | null };

export type CastPollVoteMutationVariables = Exact<{
  pollId: Scalars['String']['input'];
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  newOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type CastPollVoteMutation = {
  __typename?: 'Mutation';
  castPollVote?: {
    __typename?: 'PollWidget';
    _id?: string | null;
    options?: Array<{ __typename?: 'PollOption'; description?: string | null; votes?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null } | null> | null;
  } | null;
};

export type CreatePollMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
  pollType?: InputMaybe<Scalars['String']['input']>;
  placement?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type CreatePollMutation = {
  __typename?: 'Mutation';
  createPoll?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
  } | null;
};

export type DeletePollMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
  pollId: Scalars['ID']['input'];
}>;

export type DeletePollMutation = {
  __typename?: 'Mutation';
  deletePoll?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    status?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    widgets?: Array<
      | { __typename?: 'NoteWidget'; _id?: string | null; title?: string | null; body?: string | null }
      | {
          __typename?: 'PollWidget';
          _id?: string | null;
          question?: string | null;
          pollType?: string | null;
          type?: string | null;
          hidden?: boolean | null;
          options?: Array<{
            __typename?: 'PollOption';
            description?: string | null;
            votes?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
          } | null> | null;
        }
      | null
    > | null;
  } | null;
};

export type EditPollMutationVariables = Exact<{
  pollId: Scalars['String']['input'];
  pollType?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type EditPollMutation = {
  __typename?: 'Mutation';
  editPoll?: {
    __typename?: 'PollWidget';
    _id?: string | null;
    question?: string | null;
    pollType?: string | null;
    type?: string | null;
    hidden?: boolean | null;
    options?: Array<{
      __typename?: 'PollOption';
      description?: string | null;
      votes?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    } | null> | null;
  } | null;
};

export type SubmitGpsMutationVariables = Exact<{
  timestamp: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
}>;

export type SubmitGpsMutation = { __typename?: 'Mutation'; submitGPS?: { __typename?: 'GPSsubmissionResult'; success?: boolean | null; shouldStop?: boolean | null } | null };

export type AcceptFriendRequestMutationVariables = Exact<{
  senderId: Scalars['ID']['input'];
}>;

export type AcceptFriendRequestMutation = {
  __typename?: 'Mutation';
  acceptFriendRequest?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type CancelFriendRequestMutationVariables = Exact<{
  receiverId: Scalars['ID']['input'];
}>;

export type CancelFriendRequestMutation = {
  __typename?: 'Mutation';
  cancelFriendRequest?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
  } | null;
};

export type IgnoreFriendRequestMutationVariables = Exact<{
  senderId: Scalars['ID']['input'];
}>;

export type IgnoreFriendRequestMutation = {
  __typename?: 'Mutation';
  ignoreFriendRequest?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type RemoveFriendMutationVariables = Exact<{
  receiverId: Scalars['ID']['input'];
}>;

export type RemoveFriendMutation = {
  __typename?: 'Mutation';
  removeFriend?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type SendFriendRequestMutationVariables = Exact<{
  receiverId: Scalars['ID']['input'];
}>;

export type SendFriendRequestMutation = {
  __typename?: 'Mutation';
  sendFriendRequest?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type SendFriendRequestByUsernameMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type SendFriendRequestByUsernameMutation = {
  __typename?: 'Mutation';
  sendFriendRequestByUsername?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetAllEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllEventsQuery = {
  __typename?: 'Query';
  allEvents?: Array<{
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    status?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: { __typename?: 'Location'; name?: string | null } | null;
    host?: { __typename?: 'User'; _id?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
  } | null> | null;
};

export type GetEventQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEventQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    status?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    widgets?: Array<
      | { __typename?: 'NoteWidget'; _id?: string | null; title?: string | null; body?: string | null }
      | {
          __typename?: 'PollWidget';
          _id?: string | null;
          question?: string | null;
          pollType?: string | null;
          placement?: string | null;
          hidden?: boolean | null;
          options?: Array<{
            __typename?: 'PollOption';
            description?: string | null;
            votes?: Array<{
              __typename?: 'User';
              _id?: string | null;
              name?: string | null;
              email?: string | null;
              nickname?: string | null;
              picture?: string | null;
              auth0_id?: string | null;
              streamcUsername?: string | null;
              streamcToken?: string | null;
              deletionPending?: boolean | null;
            } | null> | null;
          } | null> | null;
        }
      | null
    > | null;
  } | null;
};

export type GetEventByScIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetEventByScIdQuery = { __typename?: 'Query'; eventBySCId?: { __typename?: 'Event'; _id?: string | null } | null };

export type GetInvitationQueryVariables = Exact<{
  inviteRef: Scalars['String']['input'];
}>;

export type GetInvitationQuery = {
  image: string;
  __typename?: 'Query';
  invitation?: {
    __typename?: 'Event';
    _id?: string | null;
    name?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    image?: string | null;
    description?: string | null;
    streamcChannelID?: string | null;
    inviteReferral?: string | null;
    location?: {
      __typename?: 'Location';
      name?: string | null;
      address?: string | null;
      coordinate?: { __typename?: 'Coordinate'; latitude?: number | null; longitude?: number | null } | null;
    } | null;
    host?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
    additionalHosts?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    attending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    notAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    maybeAttending?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    invited?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
    widgets?: Array<
      | {
          __typename?: 'CarpoolWidget';
          _id?: string | null;
          cars?: Array<{
            __typename?: 'CarpoolCar';
            _id?: string | null;
            name?: string | null;
            notes?: string | null;
            driver?: { __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null;
            passengers?: Array<{ __typename?: 'User'; _id?: string | null; name?: string | null; nickname?: string | null; picture?: string | null } | null> | null;
          } | null> | null;
        }
      | { __typename?: 'NoteWidget'; _id?: string | null; title?: string | null; body?: string | null }
      | {
          __typename?: 'PollWidget';
          _id?: string | null;
          question?: string | null;
          pollType?: string | null;
          placement?: string | null;
          hidden?: boolean | null;
          options?: Array<{
            __typename?: 'PollOption';
            description?: string | null;
            votes?: Array<{
              __typename?: 'User';
              _id?: string | null;
              name?: string | null;
              email?: string | null;
              nickname?: string | null;
              picture?: string | null;
              auth0_id?: string | null;
              streamcUsername?: string | null;
              streamcToken?: string | null;
              deletionPending?: boolean | null;
            } | null> | null;
          } | null> | null;
        }
      | null
    > | null;
  } | null;
};

export type GetGpsDataQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type GetGpsDataQuery = {
  __typename?: 'Query';
  getGPSData?: Array<{
    __typename?: 'GPSDisplay';
    _id?: string | null;
    title?: string | null;
    image?: string | null;
    timestamp?: number | null;
    longitude?: number | null;
    latitude?: number | null;
    shareLocation?: string | null;
  } | null> | null;
};

export type GetLatestVersionQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestVersionQuery = { __typename?: 'Query'; getLatestVersion?: string | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    nickname?: string | null;
    picture?: string | null;
    auth0_id?: string | null;
    streamcUsername?: string | null;
    streamcToken?: string | null;
    deletionPending?: boolean | null;
    friends?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    outgoingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
    incomingFriendRequests?: Array<{
      __typename?: 'User';
      _id?: string | null;
      name?: string | null;
      email?: string | null;
      nickname?: string | null;
      picture?: string | null;
      auth0_id?: string | null;
      streamcUsername?: string | null;
      streamcToken?: string | null;
      deletionPending?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetUserByUsernameQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type GetUserByUsernameQuery = {
  __typename?: 'Query';
  userByUsername?: {
    __typename?: 'User';
    _id?: string | null;
    nickname?: string | null;
    name?: string | null;
    picture?: string | null;
    friends?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    incomingFriendRequests?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    outgoingFriendRequests?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
  } | null;
};

export type CheckUsernameQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type CheckUsernameQuery = { __typename?: 'Query'; isUsernameAvailable?: boolean | null };

export type SearchByUsernameQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type SearchByUsernameQuery = {
  __typename?: 'Query';
  searchByUsername?: Array<{
    __typename?: 'User';
    _id?: string | null;
    nickname?: string | null;
    name?: string | null;
    picture?: string | null;
    friends?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    incomingFriendRequests?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
    outgoingFriendRequests?: Array<{ __typename?: 'User'; _id?: string | null } | null> | null;
  } | null> | null;
};

export const EventBasicFieldsFragmentDoc = gql`
  fragment EventBasicFields on Event {
    _id
    name
    startTime
    endTime
    location {
      name
      address
      coordinate {
        longitude
        latitude
      }
    }
    image
    description
    streamcChannelID
  }
`;
export const EventAllFieldsFragmentDoc = gql`
  fragment EventAllFields on Event {
    _id
    name
    startTime
    endTime
    location {
      name
      address
      coordinate {
        latitude
        longitude
      }
    }
    image
    description
    streamcChannelID
    host {
      _id
      name
      nickname
      picture
    }
    additionalHosts {
      _id
      name
      nickname
      picture
    }
    attending {
      _id
      name
      nickname
      picture
    }
    notAttending {
      _id
      name
      nickname
      picture
    }
    maybeAttending {
      _id
      name
      nickname
      picture
    }
    invited {
      _id
      name
      nickname
      picture
    }
    inviteReferral
  }
`;
export const UserBasicFieldsFragmentDoc = gql`
  fragment UserBasicFields on User {
    _id
    name
    email
    nickname
    picture
    auth0_id
    streamcUsername
    streamcToken
    deletionPending
  }
`;
export const UserAllFieldsFragmentDoc = gql`
  fragment UserAllFields on User {
    _id
    name
    email
    nickname
    picture
    auth0_id
    streamcUsername
    streamcToken
    deletionPending
    friends {
      ...UserBasicFields
    }
    outgoingFriendRequests {
      ...UserBasicFields
    }
    incomingFriendRequests {
      ...UserBasicFields
    }
  }
  ${UserBasicFieldsFragmentDoc}
`;
export const NoteWidgetFieldsFragmentDoc = gql`
  fragment NoteWidgetFields on NoteWidget {
    _id
    title
    body
  }
`;
export const PollWidgetFieldsFragmentDoc = gql`
  fragment PollWidgetFields on PollWidget {
    _id
    question
    pollType
    options {
      description
      votes {
        _id
        name
        nickname
        picture
      }
    }
    type
    hidden
  }
`;
export const WidgetFieldsFragmentDoc = gql`
  fragment WidgetFields on Widgets {
    ... on NoteWidget {
      ...NoteWidgetFields
    }
    ... on PollWidget {
      ...PollWidgetFields
    }
  }
  ${NoteWidgetFieldsFragmentDoc}
  ${PollWidgetFieldsFragmentDoc}
`;
export const AddAdditionalHostDocument = gql`
  mutation addAdditionalHost($eventId: ID!, $additionalHostId: ID!) {
    addAdditionalHost(eventId: $eventId, additionalHostId: $additionalHostId) {
      ...EventAllFields
    }
  }
  ${EventAllFieldsFragmentDoc}
`;
export type AddAdditionalHostMutationFn = Apollo.MutationFunction<AddAdditionalHostMutation, AddAdditionalHostMutationVariables>;

/**
 * __useAddAdditionalHostMutation__
 *
 * To run a mutation, you first call `useAddAdditionalHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdditionalHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdditionalHostMutation, { data, loading, error }] = useAddAdditionalHostMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      additionalHostId: // value for 'additionalHostId'
 *   },
 * });
 */
export function useAddAdditionalHostMutation(baseOptions?: Apollo.MutationHookOptions<AddAdditionalHostMutation, AddAdditionalHostMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAdditionalHostMutation, AddAdditionalHostMutationVariables>(AddAdditionalHostDocument, options);
}
export type AddAdditionalHostMutationHookResult = ReturnType<typeof useAddAdditionalHostMutation>;
export type AddAdditionalHostMutationResult = Apollo.MutationResult<AddAdditionalHostMutation>;
export type AddAdditionalHostMutationOptions = Apollo.BaseMutationOptions<AddAdditionalHostMutation, AddAdditionalHostMutationVariables>;
export const CreateEventDocument = gql`
  mutation createEvent(
    $description: String
    $locationName: String
    $locationAddress: String
    $locationLongitude: Float
    $locationLatitude: Float
    $startTime: Float
    $endTime: Float
    $name: String!
    $image: String
  ) {
    createEvent(
      description: $description
      locationName: $locationName
      locationAddress: $locationAddress
      locationLongitude: $locationLongitude
      locationLatitude: $locationLatitude
      startTime: $startTime
      endTime: $endTime
      name: $name
      image: $image
    ) {
      _id
      name
      startTime
      endTime
      location {
        name
        address
        coordinate {
          latitude
          longitude
        }
      }
      image
      description
      streamcChannelID
      host {
        _id
      }
      attending {
        _id
      }
      notAttending {
        _id
      }
    }
  }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      description: // value for 'description'
 *      locationName: // value for 'locationName'
 *      locationAddress: // value for 'locationAddress'
 *      locationLongitude: // value for 'locationLongitude'
 *      locationLatitude: // value for 'locationLatitude'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      name: // value for 'name'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const EditEventDocument = gql`
  mutation editEvent(
    $eventId: String!
    $description: String
    $locationName: String
    $locationAddress: String
    $locationLongitude: Float
    $locationLatitude: Float
    $startTime: Float
    $endTime: Float
    $name: String
    $image: String
  ) {
    editEvent(
      eventId: $eventId
      description: $description
      locationName: $locationName
      locationAddress: $locationAddress
      locationLongitude: $locationLongitude
      locationLatitude: $locationLatitude
      startTime: $startTime
      endTime: $endTime
      name: $name
      image: $image
    ) {
      ...EventBasicFields
    }
  }
  ${EventBasicFieldsFragmentDoc}
`;
export type EditEventMutationFn = Apollo.MutationFunction<EditEventMutation, EditEventMutationVariables>;

/**
 * __useEditEventMutation__
 *
 * To run a mutation, you first call `useEditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventMutation, { data, loading, error }] = useEditEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      description: // value for 'description'
 *      locationName: // value for 'locationName'
 *      locationAddress: // value for 'locationAddress'
 *      locationLongitude: // value for 'locationLongitude'
 *      locationLatitude: // value for 'locationLatitude'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      name: // value for 'name'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useEditEventMutation(baseOptions?: Apollo.MutationHookOptions<EditEventMutation, EditEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditEventMutation, EditEventMutationVariables>(EditEventDocument, options);
}
export type EditEventMutationHookResult = ReturnType<typeof useEditEventMutation>;
export type EditEventMutationResult = Apollo.MutationResult<EditEventMutation>;
export type EditEventMutationOptions = Apollo.BaseMutationOptions<EditEventMutation, EditEventMutationVariables>;
export const EditLocationSharingDocument = gql`
  mutation editLocationSharing($eventId: String!, $shareLocation: Boolean!) {
    editLocationSharing(eventId: $eventId, shareLocation: $shareLocation)
  }
`;
export type EditLocationSharingMutationFn = Apollo.MutationFunction<EditLocationSharingMutation, EditLocationSharingMutationVariables>;

/**
 * __useEditLocationSharingMutation__
 *
 * To run a mutation, you first call `useEditLocationSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLocationSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLocationSharingMutation, { data, loading, error }] = useEditLocationSharingMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      shareLocation: // value for 'shareLocation'
 *   },
 * });
 */
export function useEditLocationSharingMutation(baseOptions?: Apollo.MutationHookOptions<EditLocationSharingMutation, EditLocationSharingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditLocationSharingMutation, EditLocationSharingMutationVariables>(EditLocationSharingDocument, options);
}
export type EditLocationSharingMutationHookResult = ReturnType<typeof useEditLocationSharingMutation>;
export type EditLocationSharingMutationResult = Apollo.MutationResult<EditLocationSharingMutation>;
export type EditLocationSharingMutationOptions = Apollo.BaseMutationOptions<EditLocationSharingMutation, EditLocationSharingMutationVariables>;
export const EditUserDocument = gql`
  mutation editUser($name: String!, $username: String!) {
    editUser(name: $name, username: $username) {
      ...UserBasicFields
    }
  }
  ${UserBasicFieldsFragmentDoc}
`;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const AcceptInviteDocument = gql`
  mutation acceptInvite($inviteRef: String!) {
    acceptInvite(inviteRef: $inviteRef) {
      _id
    }
  }
`;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      inviteRef: // value for 'inviteRef'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, options);
}
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const InvitationResponseDocument = gql`
  mutation invitationResponse($inviteRef: String!, $response: InvitationResponse!) {
    invitationResponse(inviteRef: $inviteRef, response: $response) {
      _id
    }
  }
`;
export type InvitationResponseMutationFn = Apollo.MutationFunction<InvitationResponseMutation, InvitationResponseMutationVariables>;

/**
 * __useInvitationResponseMutation__
 *
 * To run a mutation, you first call `useInvitationResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitationResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitationResponseMutation, { data, loading, error }] = useInvitationResponseMutation({
 *   variables: {
 *      inviteRef: // value for 'inviteRef'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useInvitationResponseMutation(baseOptions?: Apollo.MutationHookOptions<InvitationResponseMutation, InvitationResponseMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvitationResponseMutation, InvitationResponseMutationVariables>(InvitationResponseDocument, options);
}
export type InvitationResponseMutationHookResult = ReturnType<typeof useInvitationResponseMutation>;
export type InvitationResponseMutationResult = Apollo.MutationResult<InvitationResponseMutation>;
export type InvitationResponseMutationOptions = Apollo.BaseMutationOptions<InvitationResponseMutation, InvitationResponseMutationVariables>;
export const RejectInviteDocument = gql`
  mutation rejectInvite($inviteRef: String!) {
    rejectInvite(inviteRef: $inviteRef) {
      ...EventAllFields
    }
  }
  ${EventAllFieldsFragmentDoc}
`;
export type RejectInviteMutationFn = Apollo.MutationFunction<RejectInviteMutation, RejectInviteMutationVariables>;

/**
 * __useRejectInviteMutation__
 *
 * To run a mutation, you first call `useRejectInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectInviteMutation, { data, loading, error }] = useRejectInviteMutation({
 *   variables: {
 *      inviteRef: // value for 'inviteRef'
 *   },
 * });
 */
export function useRejectInviteMutation(baseOptions?: Apollo.MutationHookOptions<RejectInviteMutation, RejectInviteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectInviteMutation, RejectInviteMutationVariables>(RejectInviteDocument, options);
}
export type RejectInviteMutationHookResult = ReturnType<typeof useRejectInviteMutation>;
export type RejectInviteMutationResult = Apollo.MutationResult<RejectInviteMutation>;
export type RejectInviteMutationOptions = Apollo.BaseMutationOptions<RejectInviteMutation, RejectInviteMutationVariables>;
export const SendInvitesDocument = gql`
  mutation sendInvites($eventId: String!, $invitees: [ID]!) {
    sendInvites(eventId: $eventId, invitees: $invitees) {
      ...EventAllFields
    }
  }
  ${EventAllFieldsFragmentDoc}
`;
export type SendInvitesMutationFn = Apollo.MutationFunction<SendInvitesMutation, SendInvitesMutationVariables>;

/**
 * __useSendInvitesMutation__
 *
 * To run a mutation, you first call `useSendInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitesMutation, { data, loading, error }] = useSendInvitesMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      invitees: // value for 'invitees'
 *   },
 * });
 */
export function useSendInvitesMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitesMutation, SendInvitesMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendInvitesMutation, SendInvitesMutationVariables>(SendInvitesDocument, options);
}
export type SendInvitesMutationHookResult = ReturnType<typeof useSendInvitesMutation>;
export type SendInvitesMutationResult = Apollo.MutationResult<SendInvitesMutation>;
export type SendInvitesMutationOptions = Apollo.BaseMutationOptions<SendInvitesMutation, SendInvitesMutationVariables>;
export const RemoveAdditionalHostDocument = gql`
  mutation removeAdditionalHost($eventId: ID!, $additionalHostId: ID!) {
    removeAdditionalHost(eventId: $eventId, additionalHostId: $additionalHostId) {
      ...EventAllFields
    }
  }
  ${EventAllFieldsFragmentDoc}
`;
export type RemoveAdditionalHostMutationFn = Apollo.MutationFunction<RemoveAdditionalHostMutation, RemoveAdditionalHostMutationVariables>;

/**
 * __useRemoveAdditionalHostMutation__
 *
 * To run a mutation, you first call `useRemoveAdditionalHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdditionalHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdditionalHostMutation, { data, loading, error }] = useRemoveAdditionalHostMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      additionalHostId: // value for 'additionalHostId'
 *   },
 * });
 */
export function useRemoveAdditionalHostMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdditionalHostMutation, RemoveAdditionalHostMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAdditionalHostMutation, RemoveAdditionalHostMutationVariables>(RemoveAdditionalHostDocument, options);
}
export type RemoveAdditionalHostMutationHookResult = ReturnType<typeof useRemoveAdditionalHostMutation>;
export type RemoveAdditionalHostMutationResult = Apollo.MutationResult<RemoveAdditionalHostMutation>;
export type RemoveAdditionalHostMutationOptions = Apollo.BaseMutationOptions<RemoveAdditionalHostMutation, RemoveAdditionalHostMutationVariables>;
export const CreateNoteDocument = gql`
  mutation createNote($eventId: ID!, $title: String, $body: String!) {
    createNote(eventId: $eventId, title: $title, body: $body) {
      ...EventAllFields
    }
  }
  ${EventAllFieldsFragmentDoc}
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const DeleteNoteDocument = gql`
  mutation deleteNote($eventId: ID!, $noteId: ID!) {
    deleteNote(eventId: $eventId, noteId: $noteId) {
      _id
      name
      startTime
      endTime
      status
      location {
        name
        address
        coordinate {
          latitude
          longitude
        }
      }
      image
      description
      streamcChannelID
      host {
        _id
        name
        nickname
        picture
      }
      attending {
        _id
        name
        nickname
        picture
      }
      notAttending {
        _id
        name
        nickname
        picture
      }
      widgets {
        ... on NoteWidget {
          _id
          title
          body
        }
        ... on PollWidget {
          _id
          question
          pollType
          options {
            description
            votes {
              _id
              name
              nickname
              picture
            }
          }
          type
          hidden
        }
      }
      inviteReferral
    }
  }
`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
}
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const EditNoteDocument = gql`
  mutation editNote($noteId: String!, $title: String, $body: String!) {
    editNote(noteId: $noteId, title: $title, body: $body) {
      ...NoteWidgetFields
    }
  }
  ${NoteWidgetFieldsFragmentDoc}
`;
export type EditNoteMutationFn = Apollo.MutationFunction<EditNoteMutation, EditNoteMutationVariables>;

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useEditNoteMutation(baseOptions?: Apollo.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, options);
}
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = Apollo.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = Apollo.BaseMutationOptions<EditNoteMutation, EditNoteMutationVariables>;
export const CastPollVoteDocument = gql`
  mutation castPollVote($pollId: String!, $options: [String], $newOptions: [String]) {
    castPollVote(pollId: $pollId, options: $options, newOptions: $newOptions) {
      _id
      options {
        description
        votes {
          _id
        }
      }
    }
  }
`;
export type CastPollVoteMutationFn = Apollo.MutationFunction<CastPollVoteMutation, CastPollVoteMutationVariables>;

/**
 * __useCastPollVoteMutation__
 *
 * To run a mutation, you first call `useCastPollVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCastPollVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [castPollVoteMutation, { data, loading, error }] = useCastPollVoteMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      options: // value for 'options'
 *      newOptions: // value for 'newOptions'
 *   },
 * });
 */
export function useCastPollVoteMutation(baseOptions?: Apollo.MutationHookOptions<CastPollVoteMutation, CastPollVoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CastPollVoteMutation, CastPollVoteMutationVariables>(CastPollVoteDocument, options);
}
export type CastPollVoteMutationHookResult = ReturnType<typeof useCastPollVoteMutation>;
export type CastPollVoteMutationResult = Apollo.MutationResult<CastPollVoteMutation>;
export type CastPollVoteMutationOptions = Apollo.BaseMutationOptions<CastPollVoteMutation, CastPollVoteMutationVariables>;
export const CreatePollDocument = gql`
  mutation createPoll($eventId: ID!, $pollType: String, $placement: String, $question: String, $options: [String]) {
    createPoll(eventId: $eventId, pollType: $pollType, placement: $placement, question: $question, options: $options) {
      ...EventAllFields
    }
  }
  ${EventAllFieldsFragmentDoc}
`;
export type CreatePollMutationFn = Apollo.MutationFunction<CreatePollMutation, CreatePollMutationVariables>;

/**
 * __useCreatePollMutation__
 *
 * To run a mutation, you first call `useCreatePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollMutation, { data, loading, error }] = useCreatePollMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      pollType: // value for 'pollType'
 *      placement: // value for 'placement'
 *      question: // value for 'question'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreatePollMutation(baseOptions?: Apollo.MutationHookOptions<CreatePollMutation, CreatePollMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePollMutation, CreatePollMutationVariables>(CreatePollDocument, options);
}
export type CreatePollMutationHookResult = ReturnType<typeof useCreatePollMutation>;
export type CreatePollMutationResult = Apollo.MutationResult<CreatePollMutation>;
export type CreatePollMutationOptions = Apollo.BaseMutationOptions<CreatePollMutation, CreatePollMutationVariables>;
export const DeletePollDocument = gql`
  mutation deletePoll($eventId: ID!, $pollId: ID!) {
    deletePoll(eventId: $eventId, pollId: $pollId) {
      _id
      name
      startTime
      endTime
      status
      location {
        name
        address
        coordinate {
          latitude
          longitude
        }
      }
      image
      description
      streamcChannelID
      host {
        _id
        name
        nickname
        picture
      }
      attending {
        _id
        name
        nickname
        picture
      }
      notAttending {
        _id
        name
        nickname
        picture
      }
      widgets {
        ... on NoteWidget {
          _id
          title
          body
        }
        ... on PollWidget {
          _id
          question
          pollType
          options {
            description
            votes {
              _id
              name
              nickname
              picture
            }
          }
          type
          hidden
        }
      }
      inviteReferral
    }
  }
`;
export type DeletePollMutationFn = Apollo.MutationFunction<DeletePollMutation, DeletePollMutationVariables>;

/**
 * __useDeletePollMutation__
 *
 * To run a mutation, you first call `useDeletePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePollMutation, { data, loading, error }] = useDeletePollMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      pollId: // value for 'pollId'
 *   },
 * });
 */
export function useDeletePollMutation(baseOptions?: Apollo.MutationHookOptions<DeletePollMutation, DeletePollMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePollMutation, DeletePollMutationVariables>(DeletePollDocument, options);
}
export type DeletePollMutationHookResult = ReturnType<typeof useDeletePollMutation>;
export type DeletePollMutationResult = Apollo.MutationResult<DeletePollMutation>;
export type DeletePollMutationOptions = Apollo.BaseMutationOptions<DeletePollMutation, DeletePollMutationVariables>;
export const EditPollDocument = gql`
  mutation editPoll($pollId: String!, $pollType: String, $question: String, $options: [String]) {
    editPoll(pollId: $pollId, pollType: $pollType, question: $question, options: $options) {
      ...PollWidgetFields
    }
  }
  ${PollWidgetFieldsFragmentDoc}
`;
export type EditPollMutationFn = Apollo.MutationFunction<EditPollMutation, EditPollMutationVariables>;

/**
 * __useEditPollMutation__
 *
 * To run a mutation, you first call `useEditPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPollMutation, { data, loading, error }] = useEditPollMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      pollType: // value for 'pollType'
 *      question: // value for 'question'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useEditPollMutation(baseOptions?: Apollo.MutationHookOptions<EditPollMutation, EditPollMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPollMutation, EditPollMutationVariables>(EditPollDocument, options);
}
export type EditPollMutationHookResult = ReturnType<typeof useEditPollMutation>;
export type EditPollMutationResult = Apollo.MutationResult<EditPollMutation>;
export type EditPollMutationOptions = Apollo.BaseMutationOptions<EditPollMutation, EditPollMutationVariables>;
export const SubmitGpsDocument = gql`
  mutation submitGPS($timestamp: Float!, $longitude: Float!, $latitude: Float!) {
    submitGPS(timestamp: $timestamp, longitude: $longitude, latitude: $latitude) {
      success
      shouldStop
    }
  }
`;
export type SubmitGpsMutationFn = Apollo.MutationFunction<SubmitGpsMutation, SubmitGpsMutationVariables>;

/**
 * __useSubmitGpsMutation__
 *
 * To run a mutation, you first call `useSubmitGpsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGpsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGpsMutation, { data, loading, error }] = useSubmitGpsMutation({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *   },
 * });
 */
export function useSubmitGpsMutation(baseOptions?: Apollo.MutationHookOptions<SubmitGpsMutation, SubmitGpsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitGpsMutation, SubmitGpsMutationVariables>(SubmitGpsDocument, options);
}
export type SubmitGpsMutationHookResult = ReturnType<typeof useSubmitGpsMutation>;
export type SubmitGpsMutationResult = Apollo.MutationResult<SubmitGpsMutation>;
export type SubmitGpsMutationOptions = Apollo.BaseMutationOptions<SubmitGpsMutation, SubmitGpsMutationVariables>;
export const AcceptFriendRequestDocument = gql`
  mutation acceptFriendRequest($senderId: ID!) {
    acceptFriendRequest(senderId: $senderId) {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;
export type AcceptFriendRequestMutationFn = Apollo.MutationFunction<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>;

/**
 * __useAcceptFriendRequestMutation__
 *
 * To run a mutation, you first call `useAcceptFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptFriendRequestMutation, { data, loading, error }] = useAcceptFriendRequestMutation({
 *   variables: {
 *      senderId: // value for 'senderId'
 *   },
 * });
 */
export function useAcceptFriendRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>(AcceptFriendRequestDocument, options);
}
export type AcceptFriendRequestMutationHookResult = ReturnType<typeof useAcceptFriendRequestMutation>;
export type AcceptFriendRequestMutationResult = Apollo.MutationResult<AcceptFriendRequestMutation>;
export type AcceptFriendRequestMutationOptions = Apollo.BaseMutationOptions<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>;
export const CancelFriendRequestDocument = gql`
  mutation cancelFriendRequest($receiverId: ID!) {
    cancelFriendRequest(receiverId: $receiverId) {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;
export type CancelFriendRequestMutationFn = Apollo.MutationFunction<CancelFriendRequestMutation, CancelFriendRequestMutationVariables>;

/**
 * __useCancelFriendRequestMutation__
 *
 * To run a mutation, you first call `useCancelFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFriendRequestMutation, { data, loading, error }] = useCancelFriendRequestMutation({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *   },
 * });
 */
export function useCancelFriendRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelFriendRequestMutation, CancelFriendRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelFriendRequestMutation, CancelFriendRequestMutationVariables>(CancelFriendRequestDocument, options);
}
export type CancelFriendRequestMutationHookResult = ReturnType<typeof useCancelFriendRequestMutation>;
export type CancelFriendRequestMutationResult = Apollo.MutationResult<CancelFriendRequestMutation>;
export type CancelFriendRequestMutationOptions = Apollo.BaseMutationOptions<CancelFriendRequestMutation, CancelFriendRequestMutationVariables>;
export const DeleteUserDocument = gql`
  mutation deleteUser {
    deleteUser {
      ...UserBasicFields
    }
  }
  ${UserBasicFieldsFragmentDoc}
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const IgnoreFriendRequestDocument = gql`
  mutation ignoreFriendRequest($senderId: ID!) {
    ignoreFriendRequest(senderId: $senderId) {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;
export type IgnoreFriendRequestMutationFn = Apollo.MutationFunction<IgnoreFriendRequestMutation, IgnoreFriendRequestMutationVariables>;

/**
 * __useIgnoreFriendRequestMutation__
 *
 * To run a mutation, you first call `useIgnoreFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreFriendRequestMutation, { data, loading, error }] = useIgnoreFriendRequestMutation({
 *   variables: {
 *      senderId: // value for 'senderId'
 *   },
 * });
 */
export function useIgnoreFriendRequestMutation(baseOptions?: Apollo.MutationHookOptions<IgnoreFriendRequestMutation, IgnoreFriendRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IgnoreFriendRequestMutation, IgnoreFriendRequestMutationVariables>(IgnoreFriendRequestDocument, options);
}
export type IgnoreFriendRequestMutationHookResult = ReturnType<typeof useIgnoreFriendRequestMutation>;
export type IgnoreFriendRequestMutationResult = Apollo.MutationResult<IgnoreFriendRequestMutation>;
export type IgnoreFriendRequestMutationOptions = Apollo.BaseMutationOptions<IgnoreFriendRequestMutation, IgnoreFriendRequestMutationVariables>;
export const RemoveFriendDocument = gql`
  mutation removeFriend($receiverId: ID!) {
    removeFriend(userIdToRemove: $receiverId) {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;
export type RemoveFriendMutationFn = Apollo.MutationFunction<RemoveFriendMutation, RemoveFriendMutationVariables>;

/**
 * __useRemoveFriendMutation__
 *
 * To run a mutation, you first call `useRemoveFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFriendMutation, { data, loading, error }] = useRemoveFriendMutation({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *   },
 * });
 */
export function useRemoveFriendMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFriendMutation, RemoveFriendMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFriendMutation, RemoveFriendMutationVariables>(RemoveFriendDocument, options);
}
export type RemoveFriendMutationHookResult = ReturnType<typeof useRemoveFriendMutation>;
export type RemoveFriendMutationResult = Apollo.MutationResult<RemoveFriendMutation>;
export type RemoveFriendMutationOptions = Apollo.BaseMutationOptions<RemoveFriendMutation, RemoveFriendMutationVariables>;
export const SendFriendRequestDocument = gql`
  mutation sendFriendRequest($receiverId: ID!) {
    sendFriendRequest(receiverId: $receiverId) {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;
export type SendFriendRequestMutationFn = Apollo.MutationFunction<SendFriendRequestMutation, SendFriendRequestMutationVariables>;

/**
 * __useSendFriendRequestMutation__
 *
 * To run a mutation, you first call `useSendFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFriendRequestMutation, { data, loading, error }] = useSendFriendRequestMutation({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *   },
 * });
 */
export function useSendFriendRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendFriendRequestMutation, SendFriendRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendFriendRequestMutation, SendFriendRequestMutationVariables>(SendFriendRequestDocument, options);
}
export type SendFriendRequestMutationHookResult = ReturnType<typeof useSendFriendRequestMutation>;
export type SendFriendRequestMutationResult = Apollo.MutationResult<SendFriendRequestMutation>;
export type SendFriendRequestMutationOptions = Apollo.BaseMutationOptions<SendFriendRequestMutation, SendFriendRequestMutationVariables>;
export const SendFriendRequestByUsernameDocument = gql`
  mutation sendFriendRequestByUsername($username: String!) {
    sendFriendRequestByUsername(username: $username) {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;
export type SendFriendRequestByUsernameMutationFn = Apollo.MutationFunction<SendFriendRequestByUsernameMutation, SendFriendRequestByUsernameMutationVariables>;

/**
 * __useSendFriendRequestByUsernameMutation__
 *
 * To run a mutation, you first call `useSendFriendRequestByUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFriendRequestByUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFriendRequestByUsernameMutation, { data, loading, error }] = useSendFriendRequestByUsernameMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSendFriendRequestByUsernameMutation(baseOptions?: Apollo.MutationHookOptions<SendFriendRequestByUsernameMutation, SendFriendRequestByUsernameMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendFriendRequestByUsernameMutation, SendFriendRequestByUsernameMutationVariables>(SendFriendRequestByUsernameDocument, options);
}
export type SendFriendRequestByUsernameMutationHookResult = ReturnType<typeof useSendFriendRequestByUsernameMutation>;
export type SendFriendRequestByUsernameMutationResult = Apollo.MutationResult<SendFriendRequestByUsernameMutation>;
export type SendFriendRequestByUsernameMutationOptions = Apollo.BaseMutationOptions<SendFriendRequestByUsernameMutation, SendFriendRequestByUsernameMutationVariables>;
export const GetAllEventsDocument = gql`
  query getAllEvents {
    allEvents {
      _id
      name
      startTime
      endTime
      status
      location {
        name
      }
      image
      description
      streamcChannelID
      host {
        _id
      }
      additionalHosts {
        _id
      }
      attending {
        _id
      }
      notAttending {
        _id
      }
      maybeAttending {
        _id
      }
      invited {
        _id
      }
      inviteReferral
    }
  }
`;

/**
 * __useGetAllEventsQuery__
 *
 * To run a query within a React component, call `useGetAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
}
export function useGetAllEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
}
export type GetAllEventsQueryHookResult = ReturnType<typeof useGetAllEventsQuery>;
export type GetAllEventsLazyQueryHookResult = ReturnType<typeof useGetAllEventsLazyQuery>;
export type GetAllEventsQueryResult = Apollo.QueryResult<GetAllEventsQuery, GetAllEventsQueryVariables>;
export const GetEventDocument = gql`
  query getEvent($id: String) {
    event(id: $id) {
      _id
      name
      startTime
      endTime
      status
      location {
        name
        address
        coordinate {
          latitude
          longitude
        }
      }
      image
      description
      streamcChannelID
      host {
        _id
        name
        nickname
        picture
      }
      additionalHosts {
        _id
        name
        nickname
        picture
      }
      attending {
        _id
        name
        nickname
        picture
      }
      notAttending {
        _id
        name
        nickname
        picture
      }
      maybeAttending {
        _id
        name
        nickname
        picture
      }
      invited {
        _id
        name
        nickname
        picture
      }
      widgets {
        ... on NoteWidget {
          _id
          title
          body
        }
        ... on PollWidget {
          _id
          question
          pollType
          placement
          options {
            description
            votes {
              ...UserBasicFields
            }
          }
          placement
          hidden
        }
      }
      inviteReferral
    }
  }
  ${UserBasicFieldsFragmentDoc}
`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions?: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
}
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
}
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetEventByScIdDocument = gql`
  query getEventBySCId($id: String!) {
    eventBySCId(id: $id) {
      _id
    }
  }
`;

/**
 * __useGetEventByScIdQuery__
 *
 * To run a query within a React component, call `useGetEventByScIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByScIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByScIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventByScIdQuery(baseOptions: Apollo.QueryHookOptions<GetEventByScIdQuery, GetEventByScIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventByScIdQuery, GetEventByScIdQueryVariables>(GetEventByScIdDocument, options);
}
export function useGetEventByScIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventByScIdQuery, GetEventByScIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventByScIdQuery, GetEventByScIdQueryVariables>(GetEventByScIdDocument, options);
}
export type GetEventByScIdQueryHookResult = ReturnType<typeof useGetEventByScIdQuery>;
export type GetEventByScIdLazyQueryHookResult = ReturnType<typeof useGetEventByScIdLazyQuery>;
export type GetEventByScIdQueryResult = Apollo.QueryResult<GetEventByScIdQuery, GetEventByScIdQueryVariables>;
export const GetInvitationDocument = gql`
  query getInvitation($inviteRef: String!) {
    invitation(inviteRef: $inviteRef) {
      _id
      name
      startTime
      endTime
      status
      location {
        name
        address
        coordinate {
          latitude
          longitude
        }
      }
      deleted
      image
      description
      streamcChannelID
      host {
        _id
        name
        nickname
        picture
      }
      additionalHosts {
        _id
        name
        nickname
        picture
      }
      itinerary {
        startTime
        endTime
        location {
          name
          address
          coordinate {
            latitude
            longitude
          }
        }
        description
      }
      interestedNotAttending {
        _id
      }
      attending {
        _id
        name
        nickname
        picture
      }
      notAttending {
        _id
        name
        nickname
        picture
      }
      blockedUsers {
        _id
        name
        nickname
        picture
      }
      maybeAttending {
        _id
        name
        nickname
        picture
      }
      invited {
        _id
        name
        nickname
        picture
      }
      permission {
        type
        group {
          _id
          name
        }
      }
      type
      widgets {
        ... on NoteWidget {
          _id
          title
          body
        }
        ... on PollWidget {
          _id
          question
          pollType
          placement
          options {
            description
            votes {
              ...UserBasicFields
            }
          }
          placement
          hidden
        }
        ... on CarpoolWidget {
          _id
          cars {
            _id
            driver {
              _id
              name
              nickname
              picture
            }
            passengers {
              _id
              name
              nickname
              picture
            }
            name
            notes
          }
        }
      }
      inviteReferral
    }
  }
  ${UserBasicFieldsFragmentDoc}
`;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      inviteRef: // value for 'inviteRef'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
}
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
}
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const GetGpsDataDocument = gql`
  query getGPSData($eventId: String!) {
    getGPSData(eventId: $eventId) {
      _id
      title
      image
      timestamp
      longitude
      latitude
      shareLocation
    }
  }
`;

/**
 * __useGetGpsDataQuery__
 *
 * To run a query within a React component, call `useGetGpsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGpsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGpsDataQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetGpsDataQuery(baseOptions: Apollo.QueryHookOptions<GetGpsDataQuery, GetGpsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGpsDataQuery, GetGpsDataQueryVariables>(GetGpsDataDocument, options);
}
export function useGetGpsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGpsDataQuery, GetGpsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGpsDataQuery, GetGpsDataQueryVariables>(GetGpsDataDocument, options);
}
export type GetGpsDataQueryHookResult = ReturnType<typeof useGetGpsDataQuery>;
export type GetGpsDataLazyQueryHookResult = ReturnType<typeof useGetGpsDataLazyQuery>;
export type GetGpsDataQueryResult = Apollo.QueryResult<GetGpsDataQuery, GetGpsDataQueryVariables>;
export const GetLatestVersionDocument = gql`
  query getLatestVersion {
    getLatestVersion
  }
`;

/**
 * __useGetLatestVersionQuery__
 *
 * To run a query within a React component, call `useGetLatestVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestVersionQuery, GetLatestVersionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestVersionQuery, GetLatestVersionQueryVariables>(GetLatestVersionDocument, options);
}
export function useGetLatestVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestVersionQuery, GetLatestVersionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLatestVersionQuery, GetLatestVersionQueryVariables>(GetLatestVersionDocument, options);
}
export type GetLatestVersionQueryHookResult = ReturnType<typeof useGetLatestVersionQuery>;
export type GetLatestVersionLazyQueryHookResult = ReturnType<typeof useGetLatestVersionLazyQuery>;
export type GetLatestVersionQueryResult = Apollo.QueryResult<GetLatestVersionQuery, GetLatestVersionQueryVariables>;
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    currentUser {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetUserDocument = gql`
  query getUser($id: String!) {
    user(id: $id) {
      ...UserAllFields
    }
  }
  ${UserAllFieldsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserByUsernameDocument = gql`
  query getUserByUsername($username: String!) {
    userByUsername(username: $username) {
      _id
      nickname
      name
      picture
      friends {
        _id
      }
      incomingFriendRequests {
        _id
      }
      outgoingFriendRequests {
        _id
      }
    }
  }
`;

/**
 * __useGetUserByUsernameQuery__
 *
 * To run a query within a React component, call `useGetUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserByUsernameQuery(baseOptions: Apollo.QueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, options);
}
export function useGetUserByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, options);
}
export type GetUserByUsernameQueryHookResult = ReturnType<typeof useGetUserByUsernameQuery>;
export type GetUserByUsernameLazyQueryHookResult = ReturnType<typeof useGetUserByUsernameLazyQuery>;
export type GetUserByUsernameQueryResult = Apollo.QueryResult<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>;
export const CheckUsernameDocument = gql`
  query checkUsername($username: String!) {
    isUsernameAvailable(username: $username)
  }
`;

/**
 * __useCheckUsernameQuery__
 *
 * To run a query within a React component, call `useCheckUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCheckUsernameQuery(baseOptions: Apollo.QueryHookOptions<CheckUsernameQuery, CheckUsernameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckUsernameQuery, CheckUsernameQueryVariables>(CheckUsernameDocument, options);
}
export function useCheckUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUsernameQuery, CheckUsernameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckUsernameQuery, CheckUsernameQueryVariables>(CheckUsernameDocument, options);
}
export type CheckUsernameQueryHookResult = ReturnType<typeof useCheckUsernameQuery>;
export type CheckUsernameLazyQueryHookResult = ReturnType<typeof useCheckUsernameLazyQuery>;
export type CheckUsernameQueryResult = Apollo.QueryResult<CheckUsernameQuery, CheckUsernameQueryVariables>;
export const SearchByUsernameDocument = gql`
  query searchByUsername($search: String!) {
    searchByUsername(search: $search) {
      _id
      nickname
      name
      picture
      friends {
        _id
      }
      incomingFriendRequests {
        _id
      }
      outgoingFriendRequests {
        _id
      }
    }
  }
`;

/**
 * __useSearchByUsernameQuery__
 *
 * To run a query within a React component, call `useSearchByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchByUsernameQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchByUsernameQuery(baseOptions: Apollo.QueryHookOptions<SearchByUsernameQuery, SearchByUsernameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchByUsernameQuery, SearchByUsernameQueryVariables>(SearchByUsernameDocument, options);
}
export function useSearchByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchByUsernameQuery, SearchByUsernameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchByUsernameQuery, SearchByUsernameQueryVariables>(SearchByUsernameDocument, options);
}
export type SearchByUsernameQueryHookResult = ReturnType<typeof useSearchByUsernameQuery>;
export type SearchByUsernameLazyQueryHookResult = ReturnType<typeof useSearchByUsernameLazyQuery>;
export type SearchByUsernameQueryResult = Apollo.QueryResult<SearchByUsernameQuery, SearchByUsernameQueryVariables>;

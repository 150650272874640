// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog {
  width: 35vw; }

@media (max-width: 1000px) {
  .dialog {
    width: 45vw; } }

@media (max-width: 700px) {
  .dialog {
    width: 80%; } }
`, "",{"version":3,"sources":["webpack://./src/components/modal/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;;AAEb;EACE;IACE,WAAW,EAAA,EACZ;;AAGH;EACE;IACE,UACF,EAAA,EAAC","sourcesContent":[".dialog {\n  width: 35vw;\n}\n@media (max-width: 1000px) {\n  .dialog {\n    width: 45vw;\n  }\n}\n\n@media (max-width: 700px) {\n  .dialog {\n    width: 80%\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

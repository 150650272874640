import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import auth0Client from './auth0Client';
import mixpanel from 'mixpanel-browser';

const timeout = (prom: Promise<string>, time: number) => {
  let timer: NodeJS.Timeout;
  return Promise.race([prom, new Promise((_r, rej) => (timer = setTimeout(rej, time)))]).finally(() => clearTimeout(timer));
};

const getMixPanelId = async () => {
  try {
    return await timeout(mixpanel.get_distinct_id() as Promise<string>, 1000);
  } catch (e) {
    return '';
  }
};

async function getToken() {
  const token = sessionStorage.getItem('token');
  if (!token) {
    const idToken = await auth0Client.getIdTokenClaims();
    if (idToken) sessionStorage.setItem('token', idToken.__raw);
  }
  return token;
}

const authLink = setContext(async (_, { headers }: { headers: Headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      mixpanelId: await getMixPanelId(),
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL || ''}/graphql`, // replace with your GraphQL endpoint
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;

import { Button } from 'primereact/button';
import logo from '../../assets/sideways-logo.png'; // Import the image
import { useAuth0 } from '@auth0/auth0-react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUserLazyQuery, UserBasicFieldsFragment } from '../../generate/graphql';
import { useEffect, useState } from 'react';
import ClickableAvatar from '../clickableAvatar';
import useLogin from '../../hooks/useLogin';
import mixpanel from 'mixpanel-browser';
import { currentUserIdVar } from '../../graphql/reactive/login';

const Navbar = () => {
  const navigate = useNavigate();
  const login = useLogin();
  const { logout, isAuthenticated } = useAuth0();
  const [getCurrentUser, { data, error }] = useGetCurrentUserLazyQuery();
  const [currentUser, setCurrentUser] = useState<UserBasicFieldsFragment | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentUser().catch((e) => console.log(e));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (data?.currentUser) {
      if (data.currentUser._id) mixpanel.identify(data.currentUser._id);
      setCurrentUser(data.currentUser);
      if (data.currentUser._id) currentUserIdVar(data.currentUser._id);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  const navigateHome = () => {
    navigate('/');
  };

  const authLogout = () => {
    sessionStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } }).catch((err) => console.log(err));
  };

  return (
    <div className="navbar-wrapper">
      <div className="image-wrapper">
        <img src={logo} alt="Description of Image" onClick={() => navigateHome()} className={'clickable'} />
      </div>
      <div className="option-wrapper">
        {currentUser && <ClickableAvatar {...currentUser} />}
        <div>{isAuthenticated ? <Button onClick={() => authLogout()}>Logout</Button> : <Button onClick={() => login()}>Login</Button>}</div>
      </div>
    </div>
  );
};
export default Navbar;

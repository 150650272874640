// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-wrapper {
  display: flex;
  flex-direction: "row";
  width: "auto";
  height: var(--navbar-height);
  background-color: var(--background-dark-color);
  padding: var(--spacing-md);
  box-shadow: 0px 3px 3px var(--background-color); }
  .navbar-wrapper .image-wrapper {
    display: flex;
    flex: 0 1;
    width: 100px;
    height: 38px;
    justify-content: flex-start; }
  .navbar-wrapper .option-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    align-content: center;
    justify-content: flex-end; }

.modalAndroidAppIcon {
  width: 50%;
  height: 50%; }

.modalAppleAppIcon {
  width: 43%;
  height: 43%; }
`, "",{"version":3,"sources":["webpack://./src/components/navbar/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,aAAa;EACb,4BAA4B;EAC5B,8CAA8C;EAC9C,0BAA0B;EAC1B,+CAA+C,EAAA;EAPjD;IAUI,aAAa;IACb,SAAO;IACP,YAAY;IACZ,YAAY;IACZ,2BAA2B,EAAA;EAd/B;IAmBI,aAAa;IACb,mBAAmB;IACnB,SAAO;IACP,qBAAqB;IACrB,yBAAyB,EAAA;;AAK7B;EACE,UAAU;EACV,WACF,EAAA;;AAEA;EACE,UAAU;EACV,WAAW,EAAA","sourcesContent":[".navbar-wrapper {\n  display: flex;\n  flex-direction: \"row\";\n  width: \"auto\";\n  height: var(--navbar-height);\n  background-color: var(--background-dark-color);\n  padding: var(--spacing-md);\n  box-shadow: 0px 3px 3px var(--background-color);\n\n  .image-wrapper {\n    display: flex;\n    flex: 0;\n    width: 100px;\n    height: 38px;\n    justify-content: flex-start;\n  }\n\n\n  .option-wrapper {\n    display: flex;\n    flex-direction: row;\n    flex: 1;\n    align-content: center;\n    justify-content: flex-end;\n  }\n\n}\n\n.modalAndroidAppIcon {\n  width: 50%;\n  height: 50%\n}\n\n.modalAppleAppIcon {\n  width: 43%;\n  height: 43%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

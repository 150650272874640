import { UserBasicFieldsFragment } from '../../generate/graphql';
import ClickableAvatar from '../clickableAvatar';

export interface GroupAvatarProps {
  data: UserBasicFieldsFragment[];
  admins?: UserBasicFieldsFragment[];
  max?: number; // Max number of avatars to show
  avatarSize?: number;
  scrollable?: boolean;
  onPress?: () => void;
  avatarPressable?: boolean;
}

export const GroupAvatar = ({ data, max, scrollable = true, onPress, avatarPressable = false }: GroupAvatarProps) => {
  // User testing section remains commented out or adapt as necessary
  // ...

  let maxCount = max && data.length - max;
  if (maxCount && maxCount > 99) maxCount = 99; // If we have more than 99 users, set to +99

  const innerData = () => (
    <div className={`group-avatar-wrapper ${avatarPressable ? 'clickable' : ''}`} onClick={avatarPressable ? onPress : undefined}>
      {data ? data?.slice(0, max).map((elem) => <ClickableAvatar key={elem._id} {...elem} />) : <span />}

      {data && max && data?.length > max ? <div className="avatar-plus-icon">{`+${data?.length - max}`}</div> : null}
    </div>
  );

  return <>{scrollable ? <div className="scroll-container horizontal-scroll">{innerData()}</div> : <div>{innerData()}</div>}</>;
};

import { guestListProps } from './types';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { UserBasicFieldsFragment } from '../../generate/graphql';
import { TabPanel, TabView } from 'primereact/tabview';
import './index.scss';
import { determineImageUrl } from '../../utils/determineImageUrl';

const GuestList = ({ attending, notAttending, maybeAttending, invited }: guestListProps) => {
  const showAvatarGroup = (list: (UserBasicFieldsFragment | null)[]) => {
    return list.length !== 0 ? (
      <AvatarGroup className="avatar-list-wrapper">
        {list.map((user, index) => {
          return (
            <div className={'avatar-wrapper '.concat(list?.length - 1 === index ? 'last-item' : 'add-border')}>
              <div className="avatar-image">
                <Avatar image={determineImageUrl(user?.picture)} size="large" shape="circle" />
              </div>
              <div className={'avatar-name-wrapper'}>
                <div className={'avatar-username smallBold'}>{user?.name}</div>
                <div className={'avatar-nickname xsmallRegular'}>{`@${user?.nickname ?? ''}`}</div>
              </div>
            </div>
          );
        })}
      </AvatarGroup>
    ) : (
      <div className="avatars"> No one in this list </div>
    );
  };

  return (
    <TabView>
      <TabPanel header="Going">{showAvatarGroup(attending)}</TabPanel>
      <TabPanel header="Maybe">{showAvatarGroup(maybeAttending)}</TabPanel>
      <TabPanel header="Not Going">{showAvatarGroup(notAttending)}</TabPanel>
      <TabPanel header="Invited">{showAvatarGroup(invited)}</TabPanel>
    </TabView>
  );
};
export default GuestList;

import './index.scss';

const ChatHeader = () => {
  return (
    <div className="chatHeader-wrapper">
      <div className="chatHeader-text">{'Chat'}</div>
    </div>
  );
};

export default ChatHeader;

import { useGetCurrentUserLazyQuery, useGetInvitationLazyQuery } from '../../generate/graphql';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'primeicons/primeicons.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Error from '../../components/error';
import { Channel, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import type { Channel as ChannelType } from 'stream-chat';
import ChatHeader from '../../components/chatHeader';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_CHAT_API_KEY || '');
const ChatScreen = () => {
  const navigate = useNavigate();
  const { invitationCode } = useParams();
  const { isAuthenticated } = useAuth0();
  const [getInvitation, { loading, error, data }] = useGetInvitationLazyQuery({ variables: { inviteRef: invitationCode || '' } });
  const [getCurrentUser, { loading: userLoading, data: userData, error: userError }] = useGetCurrentUserLazyQuery();
  const [channel, setChannel] = useState<ChannelType | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [userCanSeeChat, setUserCanSeeChat] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (data?.invitation?.streamcChannelID) {
      const userCanSee =
        data?.invitation?.attending?.some((a) => a && a._id === userData?.currentUser?._id) || data?.invitation?.maybeAttending?.some((a) => a && a._id === userData?.currentUser?._id);
      if (userCanSee) {
        const channel = client.channel('messaging', data.invitation.streamcChannelID);
        setChannel(channel);
        channel
          .watch()
          .then(() => {
            if (channel?.state?.members && Object.keys(channel?.state.members).some((m: string) => channel?.state.members[m].user_id === userData?.currentUser?.streamcUsername)) {
              setUserCanSeeChat(true);
            }
          })
          .catch(() => setUserCanSeeChat(false));
      } else {
        setUserCanSeeChat(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentUser().catch((e) => console.log(e));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userData?.currentUser) {
      if (userData.currentUser._id) mixpanel.identify(userData.currentUser._id);
      const pictureToUse = userData?.currentUser.picture?.includes('http')
        ? userData?.currentUser.picture
        : `https://kavi-s3-public.s3.us-west-1.amazonaws.com/${process.env.REACT_APP_ENV ?? ''}/images/${userData?.currentUser.picture ?? ''}`;
      client
        .connectUser(
          {
            id: userData.currentUser.streamcUsername ?? '',
            name: userData.currentUser.name ?? '',
            image: pictureToUse,
            kavi_id: userData.currentUser._id,
          },
          userData.currentUser.streamcToken,
        )
        .catch((e) => console.log(e));
      getInvitation().catch((e) => console.log(e));
    }
  }, [userData]);

  if (!ready || loading || userLoading)
    return (
      <div className="loading-screen-wrapper">
        <ProgressSpinner className="spinner" strokeWidth="4" fill="transparent" animationDuration=".5s" />
      </div>
    );

  if (!isAuthenticated) {
    return <div>Login to see chat</div>;
  }

  if (!userCanSeeChat) {
    return <div>You do not have access to this chat</div>;
  }

  // Screen has error
  if (error) {
    return <Error message="Sorry, we could not find your Invitation. Please double check you entered the right code and try again!" buttonText="Return Home" navigate={() => navigate(`/`)} />;
  }

  if (userError) {
    return <Error message="Error while fetching User data, please try refreshing the page!" buttonText="Return Home" navigate={() => navigate(`/`)} />;
  }

  return (
    <>
      {channel !== null && (
        <Channel channel={channel}>
          <Window>
            <ChatHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      )}
    </>
  );
};

export default ChatScreen;

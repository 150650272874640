import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '../generate/graphql';
import GuestList from '../components/guestList';

const EventDetailScreen = () => {
  const { eventId } = useParams();
  const { loading, error, data } = useGetEventQuery({ variables: { id: eventId } });

  if (error) {
    return <div> Error </div>;
  }

  if (loading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <div id="home-screen">
        <GuestList attending={data?.event?.attending ?? []} notAttending={data?.event?.notAttending ?? []} invited={data?.event?.invited || []} maybeAttending={data?.event?.maybeAttending || []} />
        <h1>Event Screen</h1>
        <br />
        <a href={`/contacts`}>Contacts</a>
        <br />
        <a href={`/invitation/q1LLDH2a`}>Invitation</a>
      </div>
    </>
  );
};

export default EventDetailScreen;

import './index.scss';
import { Link } from 'react-router-dom';

const parseTextForLinks = (text: string) => {
  if (text === undefined) return '';
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part) => {
    if (part.match(urlRegex)) {
      return <Link to={part}>{part}</Link>;
    }
    return part;
  });
};

const NoteWidget = ({ title, body }: { title: string | undefined; body: string }) => {
  return (
    <div className={'note-wrapper'}>
      <div className={'title-wrapper'}>{title && <div className={'title-text'}>{title}</div>}</div>
      <div className={'body-wrapper'}>{parseTextForLinks(body)}</div>
    </div>
  );
};
export default NoteWidget;
